import React from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'

function SpaDetailsModal({show,handleClose,data}) {
    
  return (
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
          Booking Spa Details
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Row className="gy-6">
             <Col md={6}>
          <Form.Group controlId="title">
            <Form.Label>Spa name</Form.Label>
            <Form.Control
              type="text"
              placeholder="spa name"
              name="title"
              value={data?.user?.spaId?.name}
              disabled
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="title">
            <Form.Label>Spa phone number</Form.Label>
            <Form.Control
              type="text"
              placeholder="spa phone number"
              name="title"
              disabled
              value={data?.user?.spaId?.phone_no}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="title">
            <Form.Label>Spa Address</Form.Label>
            <Form.Control
              type="text"
              placeholder="spa address"
              name="title"
              disabled
              value={data?.user?.spaId?.address}
            />
          </Form.Group>
        </Col>
        </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
  )
}

export default SpaDetailsModal