import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { addPackage, getPackageType } from "../../redux/packages";
import { useDispatch, useSelector } from "react-redux";

function AddPackagesForm() {
  const defaultImage = "https://via.placeholder.com/300?text=Image+Placeholder";
  const dispatch = useDispatch();

  const [imagePreview, setImagePreview] = useState(defaultImage);
  const [formData, setFormData] = useState({
    title: "",
    codeNumber: "",
    prices: "",
    time: "",
    description: "",
    image: null,
    packageType: "", // Add packageType field to store selected package type
  });
  const [error, setError] = useState({});
  const allPackages = useSelector((state) => state.package.allPackagesType);

  // Check if the object is empty
  function isObjectEmpty(data) {
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  // Fetch all packages when the component mounts
  useEffect(() => {
    if (allPackages && isObjectEmpty(allPackages)) {
      dispatch(getPackageType());
    }
  }, [allPackages, dispatch]);

  // Handle image preview change
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        setFormData({ ...formData, image: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setError((prev) => ({ ...prev, [name]: "" }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};

    // Validation
    if (!formData.title) newErrors.title = "Package name is required.";
    if (!formData.codeNumber)
      newErrors.codeNumber = "Generate number of code is required.";
    if (!formData.prices) newErrors.prices = "Price is required.";
    if (!formData.time) newErrors.time = "Time is required.";
    if (!formData.description)
      newErrors.description = "Description is required.";
    if (!formData.image) newErrors.image = "Image is required.";
    if (!formData.packageType) newErrors.packageType = "Package type is required."; // Validation for package type

    setError(newErrors);

    // Only proceed if there are no errors
    if (Object.keys(newErrors).length === 0) {
      // Make your API call here
      dispatch(addPackage(formData));
      // Reset form and preview if desired
    }
  };

  return (
    <Form noValidate onSubmit={handleSubmit} className="vstack gap-4">
      <Row className="gy-6">
        {/* Package Name */}
        <Col md={6}>
          <Form.Group controlId="title">
            <Form.Label>Package name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Package name"
              name="title"
              value={formData.title}
              onChange={handleChange}
              isInvalid={!!error.title}
            />
            <Form.Control.Feedback type="invalid">{error.title}</Form.Control.Feedback>
          </Form.Group>
        </Col>

        {/* Package Code */}
        <Col md={6}>
          <Form.Group controlId="codeNumber">
            <Form.Label>Generate number of code</Form.Label>
            <Form.Control
              type="text"
              placeholder="Generate number of code"
              name="codeNumber"
              value={formData.codeNumber}
              onChange={handleChange}
              isInvalid={!!error.codeNumber}
            />
            <Form.Control.Feedback type="invalid">{error.codeNumber}</Form.Control.Feedback>
          </Form.Group>
        </Col>

        {/* Price */}
        <Col md={6}>
          <Form.Group controlId="prices">
            <Form.Label>Price</Form.Label>
            <Form.Control
              type="text"
              placeholder="Price"
              name="prices"
              value={formData.prices}
              onChange={handleChange}
              isInvalid={!!error.prices}
            />
            <Form.Control.Feedback type="invalid">{error.prices}</Form.Control.Feedback>
          </Form.Group>
        </Col>

        {/* Time */}
        <Col md={6}>
          <Form.Group controlId="time">
            <Form.Label>validity of months</Form.Label>
            <Form.Control
              type="text"
              placeholder="validity of months"
              name="time"
              value={formData.time}
              onChange={handleChange}
              isInvalid={!!error.time}
            />
            <Form.Control.Feedback type="invalid">{error.time}</Form.Control.Feedback>
          </Form.Group>
        </Col>

        {/* Description */}
        <Col md={6}>
          <Form.Group controlId="description">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="description"
              value={formData.description}
              onChange={handleChange}
              isInvalid={!!error.description}
            />
            <Form.Control.Feedback type="invalid">{error.description}</Form.Control.Feedback>
          </Form.Group>
        </Col>

        {/* Package Type Dropdown */}
        <Col md={6}>
          <Form.Group controlId="packageType">
            <Form.Label>Select Package Type</Form.Label>
            <Form.Control
              as="select"
              name="packageType"
              value={formData.packageType}
              onChange={handleChange}
              isInvalid={!!error.packageType}
            >
              <option value="">Choose a package type</option>
              {allPackages?.data?.map((pkg) => (
                <option key={pkg._id} value={pkg._id}>
                  {pkg?.nameOfType}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">{error.packageType}</Form.Control.Feedback>
          </Form.Group>
        </Col>

        {/* Image Upload */}
        <Col md={6}>
          <Form.Label>Image</Form.Label>
          <div>
            <img loading="lazy" src={imagePreview} alt="Preview" className="object w-full h-56" />
          </div>
          <label htmlFor="file-input" className="w-full">
            <Form.Control
              type="file"
              id="file-input"
              name="image"
              onChange={handleImageChange}
              className="d-none"
            />
            <Form.Control.Feedback type="invalid">{error.image}</Form.Control.Feedback>
            <Form.Label htmlFor="file-input" className="btn btn-sm btn-dark mt-2 mb-0 w-full">
              Upload
            </Form.Label>
          </label>
        </Col>
      </Row>
      <Button type="submit" className="mt-3">
        Submit
      </Button>
    </Form>
  );
}

export default AddPackagesForm;
