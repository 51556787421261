import logo from "./logo.svg";
import createYourAccountBanner from "./create-your-account-banner.webp";
import loginBanner from "./login-banner.webp";
import passwordResetBanner from "./password-reset-banner.webp";
import tempProfile from "./temp-profile.webp";
import therapyzoneLight from "./therapy-zone-light.svg";
import spaBanner from "./spa-banner.webp";
import welcomeToTherapy from "./welcome-to-therapy.webp";
import listTypeCheck from "./list-type-check.svg";
import bannerExperience from "./banner.webp";
import aboutUs from "./about-us.webp";
import spaLocation from "./spa-location.webp";

import happyClients from "./happy-clients.svg";
import spaTreatments from "./spa-treatments.svg";
import therapists from "./therapists.svg";
import yearsExperience from "./years-experience.svg";
import ShareLinkPhotos from "./share-link-photos.jpg";



export {
  logo,
  createYourAccountBanner,
  loginBanner,
  passwordResetBanner,
  tempProfile,
  therapyzoneLight,
  spaBanner,
  welcomeToTherapy,
  listTypeCheck,
  bannerExperience,
  aboutUs,
  spaLocation,
  happyClients,
  spaTreatments,
  therapists,
  yearsExperience,
  ShareLinkPhotos
};
