import { createSlice } from "@reduxjs/toolkit";
import { createQRCode } from "./index";

const QRCodeSlice = createSlice({
  name: "QRCodeSlice",
  initialState: {
    createQRCodeAdd: {},
    createQRCodeAddIsLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createQRCode.fulfilled, (state, action) => {
        state.createQRCodeAdd = action.payload; // Update with QR code data
        state.createQRCodeAddIsLoading = false;
      })
      .addCase(createQRCode.pending, (state) => {
        state.createQRCodeAddIsLoading = true;
      })
      .addCase(createQRCode.rejected, (state, action) => {
        state.createQRCodeAddIsLoading = false;
        state.error = action.payload; // Store error message
      });
  },
});

export default QRCodeSlice.reducer;
