import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../apis/Api";

export const createQRCode = createAsyncThunk(
  "createQRCode/post",
  async (data, { rejectWithValue }) => {
    try {
      const backend = new API();
      const response = await backend.fetch(API.CreateQrcodeAdd(), {
        method: "POST",
      });

      // Assuming response.data contains the QR code URL
      return response; // Return the data you need (e.g., the QR code URL)
    } catch (error) {
      console.error("Error creating QR Code:", error);
      // Return only the error message or structured error object
      return rejectWithValue(error.message || 'Something went wrong');
    }
  }
);
