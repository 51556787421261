import React, { useState } from "react";
import { cashfree } from "../util/cashFree";
import axios from "axios";

const Payment = () => {
  let version = cashfree.version();

  const getSessionId = async (e) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}payment/paymentsdata`
      );

      return response.data;
    } catch (error) {
      console.error(error, "Error on getSessionId");
    }
  };

  const handlePayments = async (e) => {
    e.preventDefault(); // Prevent the form from submitting

    let sessionId = await getSessionId();

    let checkoutOptions = {
      paymentSessionId: sessionId,
      returnUrl: `${process.env.REACT_APP_API_KEY}payment/status/{order_id}`,
    };

    cashfree.checkout(checkoutOptions).then(function (result) {
      if (result.error) {
        alert(result.error.message);
      }
      if (result.redirect) {
        alert("Redirection");
      }
    });
  };

  return (
    <form>
      <button type="submit" onClick={handlePayments}>
        Pay
      </button>
    </form>
  );
};

export default Payment;
