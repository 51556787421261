import { api_endpoints, api_host } from "./constants";

export default class API {
  constructor() {}

  static PackageList() {
    return api_host + api_endpoints.packagesListGet;
  }
  static PackageListType() {
    return api_host + api_endpoints.packageListTypeGet;
  }
  static PackageAddType() {
    return api_host + api_endpoints.packageAddTypeAdd;
  }  
  static SpauserDetailData() {
    return api_host + api_endpoints.spauserDetailData;
  }
  static GetUserRefer() {
    return api_host + api_endpoints.getUserRefer;
  }
  static RefAddBookSpa() {
    return api_host + api_endpoints.refAddBookSpa;
  }
  static ActiveSpa(name, address) {
    return (
      api_host +
      api_endpoints.activeSpaList +
      "?" +
      `${name ? "name=" + name + "&" : ""}` +
      `${address ? "address=" + address + "&" : ""}`
    );
  }
  static CreateQrcodeAdd() {
    return api_host + api_endpoints.createQr;
  }
  static PackageAdd() {
    return api_host + api_endpoints.packagesListAdd;
  }
  static LoginApi() {
    return api_host + api_endpoints.loginApi;
  }
  static LoginApiData() {
    return api_host + api_endpoints.loginApi;
  }
  static SpaLoginApi() {
    return api_host + api_endpoints.spaLoginApi;
  }
  static AddBookSpa() {
    return api_host + api_endpoints.bookSpa;
  }
  static GetUserBookSpa() {
    return api_host + api_endpoints.getuserBookSpa;
  }
  static GetAdminBookSpa() {
    return api_host + api_endpoints.getAdminBookSpa;
  }
  static LoginAdminApi() {
    return api_host + api_endpoints.adminLoginApi;
  }
  static GetAllSpa() {
    return api_host + api_endpoints.getAllSpa;
  } 
   static GetAllBookSpa() {
    return api_host + api_endpoints.getAllBookSpa;
  }
  static UpdateUser(id) {
    return api_host + api_endpoints.updateUserProfile + id;
  }
  static UpdateSpa(id) {
    return api_host + api_endpoints.updateSpaProfile + id;
  }
  _checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else if (response.status === 401) {
      return response;
    } else if (response.status === 400) {
      return response;
    } else {
      throw new Error(response);
    }
  }

  async fetch(url, options) {
    const headers = {
      Accept: "multipart/form-data",
      "access-control-allow-origin": "*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("therapyToken")}`, // Replace with your actual token
    };
    const response = await fetch(url, {
      headers,
      ...options,
    });
    const response_1 = await this._checkStatus(response);
    return response_1.json();
  }
}
