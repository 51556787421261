import React, { useEffect, useState } from "react";
import { Badge, Card, Container, Row, Table } from "react-bootstrap";
import { Header, Footer } from "../components";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { getUserBookSpa } from "../redux/bookspa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useNavigate } from "react-router";
import SpaDetailsModal from "../components/Modal/SpaDetailsModal";

function UserPackage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getUserSpaBook = useSelector((state) => state.bookspa.getUserSpaBook.data);

  const [data, setData] = useState([]);
  const [spaDetails,setSpaDetails]=useState()
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10; // Show 10 items per page

  useEffect(() => {
    if (!getUserSpaBook) {
      dispatch(getUserBookSpa());
    }
  }, [getUserSpaBook, dispatch]);

  useEffect(() => {
    if (getUserSpaBook && Array.isArray(getUserSpaBook)) {
      const allQrCodes = getUserSpaBook.flatMap((item) =>
        item.qrCode.map((qr) => ({
          ...qr,
          orderId: item.orderId,
          customerName: item.customerName,
          packageName: item.packageType ? item.packageType.nameOfType : "N/A"
        }))
      );
      setData(allQrCodes);
    }
  }, [getUserSpaBook]);

  const offset = currentPage * itemsPerPage;
  const currentPageData = data.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(data.length / itemsPerPage);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const handleUserBookDetails = async (id) => {
    try {
    handleShow()
      const url = `${process.env.REACT_APP_API_KEY}user/usercode/details/${id}`;
      const response = await axios.get(url, {
        headers: {
          Accept: "multipart/form-data",
          "access-control-allow-origin": "*",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("therapyToken")}`,
        },
      });
      setSpaDetails(response.data)
      return response.data;
    } catch (error) {
      console.error("Error on getSessionId:", error);
    }
  };

  const handleBookSpa = (id, packageName) => {
    navigate(`/user/scanner/${id}`, { state: { packageName } });
  };
  
  return (
    <div>
      <Header />
      <Container fluid className="py-6 gx-md-12">
        <Row>
          <Card>
            <h1>Membership</h1>
            {data.length > 0 ? (
              <>
                <Card.Body className="p-0">
                  <Table
                    responsive
                    striped
                    bordered
                    hover
                    className="table-design"
                  >
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Package Name</th>
                        <th>Code Number</th>
                        <th>Status</th>
                        <th>Time</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentPageData.map((item, index) => {
                        const createdAtDate = new Date(item.useTime);

                        // Format date
                        const options = {
                          weekday: "long",
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true,
                        };
                        const formattedDate = createdAtDate
                          .toLocaleDateString("en-GB", options)
                          .replace(",", "")                        
                        return (
                          <tr key={index}>
                            <td>{offset + index + 1}</td>
                            <td>{item.packageName}</td>
                            <td>{item.id}</td>
                            <td>
                              {item.useCode ? (
                                <Badge bg="danger">Used</Badge>
                              ) : (
                                <Badge
                                  bg="success"
                                  onClick={() => handleBookSpa(item.id,item.packageName)}
                                >
                                  Book Spa
                                </Badge>
                              )}
                            </td>
                            <td>
                              {" "}
                              {formattedDate !== "Invalid Date"
                                ? formattedDate
                                : "----"}
                            </td>
                            <td>
                              {item.useCode && (
                                <FontAwesomeIcon
                                  onClick={() => handleUserBookDetails(item.id)}
                                  icon={faEye}
                                  className="cursor-pointer"
                                />
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Card.Body>
                <Card.Footer>
                  <div className="d-flex justify-content-between align-items-center">
                    <p>
                      Showing {offset + 1} to {offset + currentPageData.length}{" "}
                      of {data.length} entries
                    </p>
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </Card.Footer>
              </>
            ) : (
              <p className="text-center">No data available</p>
            )}
          </Card>
        </Row>
      </Container>
      <Footer />

      <SpaDetailsModal show={show} handleClose={handleClose} data={spaDetails}/>
    </div>
  );
}

export default UserPackage;
