import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../apis/Api";

export const getPackage = createAsyncThunk(
  "getpackage/get",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const backend = new API();
      const response = await backend.fetch(API.PackageList(), {
        method: "GET",
      });

      // ToastMessage(response);
      // You may want to return some data here if needed
      return response;
    } catch (error) {
      console.error("Error send otp:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);

export const addPackage = createAsyncThunk(
  "addpackage/post",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const backend = new API();
      const response = await backend.fetch(API.PackageAdd(), {
        method: "POST",
        body: JSON.stringify(data),
      });

      if (response.status === 401) {        
        localStorage.clear();
      }

      // ToastMessage(response);
      // You may want to return some data here if needed
      return response;
    } catch (error) {
      console.error("Error send otp:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);

export const activeSpa = createAsyncThunk(
  "activeSpa/get",
  async ({ data, name, address }, { rejectWithValue, dispatch }) => {
    try {
      const backend = new API();
      const response = await backend.fetch(API.ActiveSpa(name, address), {
        method: "GET",
      });

      // ToastMessage(response);
      // You may want to return some data here if needed
      return response;
    } catch (error) {
      console.error("Error send otp:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);

export const getPackageType = createAsyncThunk(
  "getPackageType/get",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const backend = new API();
      const response = await backend.fetch(API.PackageListType(), {
        method: "GET",
      });

      // ToastMessage(response);
      // You may want to return some data here if needed
      return response;
    } catch (error) {
      console.error("Error send otp:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);

export const addPackageType = createAsyncThunk(
  "addPackageType/post",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const backend = new API();
      const response = await backend.fetch(API.PackageAddType(), {
        method: "POST",
        body: JSON.stringify(data),
      });

      // ToastMessage(response);
      // You may want to return some data here if needed
      return response;
    } catch (error) {
      console.error("Error send otp:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);
