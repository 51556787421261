import React, { useEffect, useState } from "react";
import { AdminHeader, AdminSidebar } from "../../components";
import {
  Button,
  Card,
  Col,
  Container,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { HiOutlinePlus } from "react-icons/hi";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { getPackageType } from "../../redux/packages";
import { clearPackageTypeAdd } from "../../redux/packages/packagesSlice";
import AddPackagesTypeForm from "../../components/Form/AddPackagesTypeForm";
import { MdDelete } from "react-icons/md";
import axios from "axios";

function AdminPackageType() {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loading, setLoading] = useState(false);
  const allPackages = useSelector((state) => state.package.allPackagesType);

  const packageAdd = useSelector((state) => state.package.packageAddType);
  useEffect(() => {
    if (packageAdd?.data?._id) {
      handleClose();
      dispatch(clearPackageTypeAdd());
      dispatch(getPackageType());
    }
  }, [packageAdd]);

  useEffect(() => {
    if (allPackages && isObjectEmpty(allPackages)) {
      dispatch(getPackageType());
    }
  }, [allPackages]);

  useEffect(() => {
    if (allPackages.data) {
      setData(allPackages.data);
    }
  }, [allPackages]);

  function isObjectEmpty(data) {
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  const [data, setData] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10; // Show 10 items per page

  // Pagination logic
  const offset = currentPage * itemsPerPage;
  const currentPageData = data.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(data.length / itemsPerPage);

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setCurrentPage(selectedPage);
  };

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      const response = await axios.delete(
        `${process.env.REACT_APP_API_KEY}admin/typeofspa/delete/${id}`
      );

      if (response.status === 200) {
        // If deletion is successful, remove the deleted package from state
        setData(data.filter((pkg) => pkg._id !== id));
      }
    } catch (err) {
      console.log("Error deleting package");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="d-flex flex-column flex-lg-row h-lg-full min-h-screen bg-gray-200 vstack">
      <AdminSidebar />
      <div className="flex-lg-1 h-lg-screen overflow-y-lg-auto">
        <AdminHeader title={"Package"} />
        <Container fluid className="py-6 gx-md-12">
          <Card>
            <Card.Header>
              <Row className="gy-4">
                <Col className="col-sm hstack justify-content-between gap-4">
                  <h4>Packages</h4>
                </Col>
                <Col className="col-sm-auto">
                  <div className="hstack justify-content-end gap-3 flex-sm-row flex-column">
                    <Button variant="primary" onClick={handleShow}>
                      <HiOutlinePlus className="me-2" />
                      Add
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Header>

            {data ? (
              <>
                <Card.Body className="p-0">
                  <Table
                    responsive
                    striped
                    bordered
                    hover
                    className="table-design"
                  >
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Package Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentPageData.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{offset + index + 1}</td>
                            <td>{item?.nameOfType}</td>
                            <td>
                              <MdDelete
                                style={{ cursor: "pointer", color: "red" }}
                                onClick={() => handleDelete(item._id)}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Card.Body>
                <Card.Footer>
                  <div className="d-flex justify-content-between align-items-center">
                    <p>
                      Showing {offset + 1} to{" "}
                      {offset + currentPageData.length} of {data.length} entries
                    </p>

                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </Card.Footer>
              </>
            ) : (
              <p className="text-center">No data available</p>
            )}
          </Card>
        </Container>
      </div>
      <Modal centered size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Packages Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddPackagesTypeForm />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AdminPackageType;
