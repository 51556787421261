import Home from "./Home";
import Login from "./Login";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";
import Payment from "./Payment";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import Packages from "./Packages";
import PackagesDetails from "./PackagesDetails";
import Services from "./Services";
import AdminDeshboard from "./Admin/AdminDeshboard";
import AddPackages from "./Admin/AddPackages";
import SpaLogin from "./Admin/SpaLogin";
import AdminCreateQrcode from "./Admin/AdminCreateQrcode";
import UserPackage from "./UserPackage";
import AdminPackage from "./Admin/AdminPackage";
import QrScanner from "./QrScanner";
import AdminLogin from "./Admin/AdminLogin";
import AdminRegister from "./Admin/AdminRegister";
import AdminSpaActive from "./Admin/AdminSpaActive";
import SpaRegister from "./Admin/SpaRegister";
import Profile from "./Profile";
import Success from "./Success";
import Failed from "./Failed";
import SpaList from "./SpaList";
import SpaProfile from "./Admin/SpaProfile";
import AdminBookSpa from "./Admin/AdminBookSpa";
import Ref from "./Ref";
import RefScanner from "./RefScanner";
import AdminPackageType from "./Admin/AdminPackageType";
import RefList from "./RefList";

export {
  Home,
  Login,
  Register,
  ForgotPassword,
  Payment,
  AboutUs,
  ContactUs,
  Packages,
  Services,
  PackagesDetails,
  AdminDeshboard,
  AddPackages,
  SpaLogin,
  AdminCreateQrcode,
  UserPackage,
  AdminPackage,
  QrScanner,
  AdminLogin,
  AdminRegister,
  AdminSpaActive,
  SpaRegister,
  Profile,
  Success,
  Failed,
  SpaList,
  SpaProfile,
  AdminBookSpa,
  Ref,
  RefScanner,
  AdminPackageType,
  RefList  
};
