import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Stack, Image } from "react-bootstrap";
import "./footer.scss";
import { FaFacebook, FaInstagram, FaLinkedinIn, FaSkype } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { therapyzoneLight } from "../../assets/images";
import Home from "../../pages/Home";
import { FiMail } from "react-icons/fi";
import { IoCallOutline } from "react-icons/io5";

const Footer = () => {
  return (
    <>
      <footer className="bg-dark footer-top">
        <Container className="pt-md-10 pt-20 pb-5 text-sm-start text-center position-relative">
          <Row className="gy-8">
            <Col xl="4" sm="6">
              <a target="_blank"></a>
              <Link to={Home}>
                <Image src={therapyzoneLight} width={200} className="mb-6" />
              </Link>
              <h6 className="text-white mb-3">Our Social Networks</h6>
              <Stack
                direction="horizontal"
                className="gap-3 justify-content-center justify-content-sm-start"
              >
                <Link
                  to="skype:live:techvoot"
                  target="_blank"
                  className="w-8 h-8 rounded-circle hstack justify-content-center bg-light bg-opacity-10 bg-opacity-100-hover text-light text-primary-hover ease-linear duration-200"
                >
                  <FaFacebook />
                </Link>
                <Link
                  to="skype:live:techvoot"
                  target="_blank"
                  className="w-8 h-8 rounded-circle hstack justify-content-center bg-light bg-opacity-10 bg-opacity-100-hover text-light text-primary-hover ease-linear duration-200"
                >
                  <FaInstagram />
                </Link>
                <Link
                  to="skype:live:techvoot"
                  target="_blank"
                  className="w-8 h-8 rounded-circle hstack justify-content-center bg-light bg-opacity-10 bg-opacity-100-hover text-light text-primary-hover ease-linear duration-200"
                >
                  <FaLinkedinIn />
                </Link>
                <Link
                  to="skype:live:techvoot"
                  target="_blank"
                  className="w-8 h-8 rounded-circle hstack justify-content-center bg-light bg-opacity-10 bg-opacity-100-hover text-light text-primary-hover ease-linear duration-200"
                >
                  <FaXTwitter />
                </Link>
                <Link
                  to="skype:live:techvoot"
                  target="_blank"
                  className="w-8 h-8 rounded-circle hstack justify-content-center bg-light bg-opacity-10 bg-opacity-100-hover text-light text-primary-hover ease-linear duration-200"
                >
                  <FaSkype />
                </Link>
              </Stack>
            </Col>
            <Col xl="4" sm="6">
              <h6 className="text-light text-md mb-4">Quick links</h6>
              <Stack
                direction="horizontal"
                className="flex-wrap gap-4 justify-content-center justify-content-sm-start"
              >
                <Link
                  className="d-block px-3 py-2 border border-gray-700 border-light-hover border-dashed rounded-pill bg-light bg-opacity-10 text-light font-semibold text-sm ease-linear duration-200"
                  to={"/"}
                >
                  Home
                </Link>
                <Link
                  className="d-block px-3 py-2 border border-gray-700 border-light-hover border-dashed rounded-pill bg-light bg-opacity-10 text-light font-semibold text-sm ease-linear duration-200"
                  to={"/about-us"}
                >
                  About Us
                </Link>
                <Link
                  className="d-block px-3 py-2 border border-gray-700 border-light-hover border-dashed rounded-pill bg-light bg-opacity-10 text-light font-semibold text-sm ease-linear duration-200"
                  to={"/services"}
                >
                  Services
                </Link>
                <Link
                  className="d-block px-3 py-2 border border-gray-700 border-light-hover border-dashed rounded-pill bg-light bg-opacity-10 text-light font-semibold text-sm ease-linear duration-200"
                  to={"/packages"}
                >
                  Packages
                </Link>
                <Link
                  className="d-block px-3 py-2 border border-gray-700 border-light-hover border-dashed rounded-pill bg-light bg-opacity-10 text-light font-semibold text-sm ease-linear duration-200"
                  to={"/contact-us"}
                >
                  Contact Us
                </Link>
              </Stack>
            </Col>
            <Col xl="4" sm="6">
              <h6 className="footer-menu-title ">Contact us</h6>
              <Stack
                direction="horizontal"
                gap={3}
                className="justify-content-center justify-content-sm-start mb-2"
              >
                <FiMail />
                <a
                  className="text-white text-sm"
                  href="mailto:growthwibes@gmail.com"
                >
                  growthwibes@gmail.com
                </a>
              </Stack>
              <Stack
                direction="horizontal"
                gap={3}
                className="justify-content-center justify-content-sm-start mb-2"
              >
                <IoCallOutline />
                <a className="text-white text-sm" href="tel:+917688005555">
                  +91 76880 05555
                </a>
              </Stack>
              <p className="text-white text-sm">
                <span className="font-bold">GROWTHWIBES MARKETING PVT.LTD</span>
                <br />
                44, Arth Business Centre (ABC Building), Opp-Torrent Power, S.P
                Ring Road, Nikol, Ahmedabad -382350
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
      <footer className="bg-gray-900 py-2">
        <Container>
          <p className="text-white text-sm text-center text-lg-start">
            © 2024 Therapy zone. All rights reserved.
          </p>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
