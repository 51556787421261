import React from 'react'
import { Card, CardBody, Col, Container, Image, Row } from 'react-bootstrap'
import { Footer, Header } from '../components'
import { spaBanner } from '../assets/images';

function Services() {
  const servicesstyle = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url(${spaBanner})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };
  return (
    <>
      <Header />
      <section className="d-flex align-items-center py-20 overflow-hidden background-no-repeat bg-center bg-cover min-h-screen-banner mb-lg-20 mb-10" style={servicesstyle}>
        {/* <div className="color-tint"></div> */}
        <Container className="my-lg-auto my-16 text-center position-relative overlap-20">
          <div className="max-w-screen-md mx-auto">
            <h1 className="mb-8 text-primary text-main">Our Services</h1>
            <p className="text-gray-200">Therapy zone offers a holistic range of beauty and services ranging from
              massages,
              facials, body scrubs,
              body wraps, manicures, pedicures, hair and luxurious wellness retreats.</p>
          </div>
        </Container>
      </section>
      {/* <section className='mb-lg-20 mb-10'>
        <Container>
          <Row className="gy-8">
            <Col lg={4} md={6}>
              <Card className="bg-gray-200 h-full">
                <Card.Img className="card-img-top h-72 object" variant="top" width={423} height={288} alt="..." src="https://naturatermospa.com/wp-content/uploads/2019/10/image-42.jpeg" />

                <CardBody className="card-body">
                  <h4>Head Shoulder Massage</h4>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section> */}
      <section className='mb-lg-20 mb-10'>
        <Container className='text-center'>
          <h2 className='mb-3'>Spa Packages and Body Treatments</h2>
          <p className='mb-6'>Come, Experience a holistic range of spa & wellness services at Sohum Luxury Spas - A Luxury Wellness Spa Chain. We've got you covered with our extensive wellness facilities, including Luxury Spas, Spa Consultancy, Spa Centres, Spa Academy, Products, Wellness Workshops, & Wellness Resorts.</p>
          <Row md={6}>
            <Col className=''>
              <div className='services-svg' >
                <svg fill="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={98} viewBox="0 0 93.2 100.2">
                  <g>
                    <path d="M35.8,71.7L35.8,71.7c-1.9-0.7-3.9-1.7-5.8-3c-2.8-2-4.9-4.4-6.5-6.8
      c-4.3-6.6-4.7-13.6-4.7-14.8c1.3,0,8.8,0.3,15.5,5.2c0,0-1.9-5.6,3.2-12.2"></path>
                    <path d="M45.3,73.4L45.3,73.4L45.3,73.4c-0.8,0.8-1.7,1.4-2.7,1.9c-2.8,1.5-7,3.3-12.3,3.3
      c-5.4,0.1-10-1.7-13.5-3.8c-4.7-2.9-7.6-6.4-8.1-7.1c0.6-0.1,6.7-3.5,9.1-4.4c0.5-0.2,1.2-0.4,2-0.5c0,0-2.4-3-2.8-6.1"></path>
                  </g>
                  <path d="M53.3,55c0,1.1-0.1,2.1-0.3,3.2c-1,7.4-5,12.3-7.1,14.4v0l-0.6,0.6l-0.6-0.6v0c-2.1-2.2-6-7.1-7.1-14.4
    c-0.2-1.1-0.2-2.2-0.3-3.2c-0.1-10.3,6.1-17.5,7.2-18.7l0.7-0.8l0.7,0.8C47.2,37.5,53.4,44.7,53.3,55z"></path>
                  <path d="M54.9,71.7L54.9,71.7c1.9-0.7,3.9-1.7,5.8-3c2.8-2,4.9-4.4,6.5-6.8c4.3-6.6,4.7-13.6,4.7-14.8
    c-1.3,0-8.8,0.3-15.5,5.2c0,0,1.9-5.6-3.2-12.2"></path>
                  <path d="M73.6,56.6c-0.5,3.1-2.8,6.1-2.8,6.1c0.8,0.1,1.5,0.3,2,0.5c2.4,1,8.5,4.4,9.1,4.4
    c-0.6,0.7-3.4,4.3-8.1,7.1c-3.5,2.1-8,3.9-13.5,3.8c-5.3-0.1-9.5-1.8-12.3-3.3c-1-0.5-2.7-1.9-2.7-1.9"></path>
                  <g>
                    <ellipse transform="matrix(0.2071 -0.9783 0.9783 0.2071 -52.5052 50.2355)" cx="4.7" cy="57.5" rx="0.3" ry="0.3"></ellipse>
                    <ellipse transform="matrix(0.3014 -0.9535 0.9535 0.3014 -47.6041 43.1242)" cx="5.6" cy="54.1" rx="0.3" ry="0.3"></ellipse>
                    <ellipse transform="matrix(0.3922 -0.9199 0.9199 0.3922 -42.4816 37.0715)" cx="6.8" cy="50.7" rx="0.3" ry="0.3"></ellipse>
                    <path d="M8.4,47.2c0.1,0.1,0.2,0.2,0.1,0.4c-0.1,0.1-0.3,0.2-0.4,0.1C8,47.6,7.9,47.4,8,47.3
      C8.1,47.2,8.3,47.1,8.4,47.2z"></path>
                    <path d="M10.2,44.1c0.1,0.1,0.2,0.3,0.1,0.4c-0.1,0.1-0.3,0.2-0.4,0.1c-0.1-0.1-0.2-0.3-0.1-0.4
      C9.9,44,10,44,10.2,44.1z"></path>
                    <path d="M12.2,41.1c0.1,0.1,0.1,0.3,0,0.4c-0.1,0.1-0.3,0.2-0.4,0.1c-0.1-0.1-0.2-0.3-0.1-0.4
      C11.9,41,12.1,41,12.2,41.1z"></path>
                    <path d="M14.5,38.4c0.1,0.1,0.1,0.3,0,0.4c-0.1,0.1-0.3,0.1-0.4,0c-0.1-0.1-0.1-0.3,0-0.4
      C14.2,38.3,14.3,38.3,14.5,38.4z"></path>
                    <path d="M17,35.8c0.1,0.1,0.1,0.3,0,0.4c-0.1,0.1-0.3,0.1-0.4,0c-0.1-0.1-0.1-0.3,0-0.4C16.7,35.7,16.9,35.7,17,35.8z"></path>
                    <path d="M19.7,33.5c0.1,0.1,0.1,0.3,0,0.4c-0.1,0.1-0.3,0.1-0.4,0c-0.1-0.1-0.1-0.3,0-0.4
      C19.4,33.4,19.6,33.4,19.7,33.5z"></path>
                    <ellipse transform="matrix(0.8293 -0.5588 0.5588 0.8293 -13.8581 17.8811)" cx="22.3" cy="31.6" rx="0.3" ry="0.3"></ellipse>
                    <ellipse transform="matrix(0.88 -0.4749 0.4749 0.88 -11.1031 15.6307)" cx="25.4" cy="29.8" rx="0.3" ry="0.3"></ellipse>
                    <ellipse transform="matrix(0.9229 -0.385 0.385 0.9229 -8.6659 13.1803)" cx="28.6" cy="28.2" rx="0.3" ry="0.3"></ellipse>
                    <path d="M32.2,26.9c0,0.2,0,0.3-0.2,0.4c-0.2,0-0.3,0-0.4-0.2c-0.1-0.2,0-0.3,0.2-0.4C32,26.6,32.1,26.7,32.2,26.9z"></path>
                    <path d="M35.6,25.9c0,0.2-0.1,0.3-0.2,0.3c-0.2,0-0.3-0.1-0.3-0.2c0-0.2,0.1-0.3,0.2-0.3S35.6,25.7,35.6,25.9z"></path>
                    <path d="M39.1,25.2c0,0.2-0.1,0.3-0.2,0.3c-0.2,0-0.3-0.1-0.3-0.2c0-0.2,0.1-0.3,0.2-0.3
      C38.9,24.9,39.1,25.1,39.1,25.2z"></path>
                    <path d="M42.6,24.9c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3S42.6,24.7,42.6,24.9z"></path>
                    <ellipse transform="matrix(9.842177e-03 -1 1 9.842177e-03 20.5839 70.4837)" cx="45.9" cy="24.8" rx="0.3" ry="0.3"></ellipse>
                    <ellipse transform="matrix(0.1108 -0.9938 0.9938 0.1108 19.0185 71.429)" cx="49.4" cy="25.1" rx="0.3" ry="0.3"></ellipse>
                    <ellipse transform="matrix(0.2094 -0.9778 0.9778 0.2094 16.7898 72.0225)" cx="52.9" cy="25.6" rx="0.3" ry="0.3"></ellipse>
                    <path d="M56.7,26.6c0,0.2-0.2,0.2-0.4,0.2c-0.2,0-0.2-0.2-0.2-0.4c0-0.2,0.2-0.2,0.4-0.2
      C56.6,26.2,56.7,26.4,56.7,26.6z"></path>
                    <path d="M60,27.7c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1-0.1-0.2-0.2-0.2-0.4c0.1-0.1,0.2-0.2,0.4-0.2
      C60,27.4,60.1,27.6,60,27.7z"></path>
                    <path d="M63.3,29.2c-0.1,0.1-0.2,0.2-0.4,0.1c-0.1-0.1-0.2-0.2-0.1-0.4c0.1-0.1,0.2-0.2,0.4-0.1
      C63.3,28.8,63.3,29,63.3,29.2z"></path>
                    <path d="M66.4,30.9C66.3,31,66.1,31.1,66,31c-0.1-0.1-0.2-0.2-0.1-0.4c0.1-0.1,0.3-0.2,0.4-0.1
      C66.4,30.6,66.5,30.8,66.4,30.9z"></path>
                    <path d="M69.3,32.9C69.2,33,69,33.1,68.9,33c-0.1-0.1-0.2-0.3-0.1-0.4c0.1-0.1,0.3-0.2,0.4-0.1
      C69.4,32.6,69.4,32.8,69.3,32.9z"></path>
                    <ellipse transform="matrix(0.6402 -0.7682 0.7682 0.6402 -1.0184 67.7802)" cx="71.9" cy="35" rx="0.3" ry="0.3"></ellipse>
                    <ellipse transform="matrix(0.7107 -0.7035 0.7035 0.7107 -4.8079 63.1916)" cx="74.4" cy="37.4" rx="0.3" ry="0.3"></ellipse>
                    <ellipse transform="matrix(0.7749 -0.6321 0.6321 0.7749 -8.0727 57.5664)" cx="76.8" cy="40.1" rx="0.3" ry="0.3"></ellipse>
                    <ellipse transform="matrix(0.8325 -0.5541 0.5541 0.8325 -10.5978 50.918)" cx="78.9" cy="43" rx="0.3" ry="0.3"></ellipse>
                    <path d="M80.9,46.3c-0.1,0.1-0.3,0-0.4-0.1c-0.1-0.1,0-0.3,0.1-0.4c0.1-0.1,0.3,0,0.4,0.1C81.1,46,81,46.2,80.9,46.3z"></path>
                    <path d="M82.5,49.5c-0.1,0.1-0.3,0-0.4-0.1c-0.1-0.1,0-0.3,0.2-0.4c0.1-0.1,0.3,0,0.4,0.1
      C82.7,49.2,82.6,49.4,82.5,49.5z"></path>
                    <path d="M83.8,52.8c-0.2,0.1-0.3,0-0.4-0.2c0-0.2,0-0.3,0.2-0.4c0.1-0.1,0.3,0,0.4,0.2C84,52.6,83.9,52.8,83.8,52.8z"></path>
                    <path d="M84.8,56.2c-0.2,0-0.3-0.1-0.4-0.2c0-0.2,0-0.3,0.2-0.3c0.2,0,0.3,0.1,0.4,0.2C85.1,56,85,56.2,84.8,56.2z"></path>
                    <path d="M85.5,59.7c-0.2,0-0.3-0.1-0.3-0.2c0-0.2,0.1-0.3,0.2-0.3c0.2,0,0.3,0.1,0.3,0.2
      C85.7,59.6,85.6,59.7,85.5,59.7z"></path>
                  </g>
                  <path d="M71.6,81c0,1.5-11.7,2.7-26.1,2.7c-14.4,0-26.1-1.2-26.1-2.7"></path>
                  <path d="M80.5,83.7c7.3,0.8,11.7,1.9,11.7,3.1c0,2.6-20.4,4.7-45.5,4.8c-25.1,0.1-45.5-2-45.5-4.6
    c0-1.2,4.4-2.3,11.6-3.2"></path>
                  <line x1="19.9" y1="62.8" x2="21.7" y2="63.5"></line>
                  <line x1="34.2" y1="52.3" x2="35" y2="53.4"></line>
                  <line x1="56.4" y1="52.3" x2="55.6" y2="53.4"></line>
                  <line x1="70.8" y1="62.8" x2="69.1" y2="63.1"></line>
                </svg>
              </div>
              <p className='mt-2 text-transform'>Body and Soul</p>
            </Col>
            <Col className=''>
              <div className='services-svg' >
                <svg fill="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={98} viewBox="0 0 93.2 100.2">
                  <g>
                    <g>
                      <path d="M35,90.4H15.7c-1,0-1.9-0.8-1.9-1.9V54.8c0-1.5,1.2-2.8,2.8-2.8h8.2"></path>
                      <path d="M32.3,52h5.3c1.5,0,2.8,1.2,2.8,2.8v3.1"></path>
                      <g>
                        <line x1="19.4" y1="47" x2="19.4" y2="52"></line>
                        <line x1="34.8" y1="47" x2="34.8" y2="52"></line>
                      </g>
                      <path d="M21.8,33.6V17.2c0-2.9,2.4-5.3,5.3-5.3l0,0c2.9,0,5.3,2.4,5.3,5.3v16.4"></path>
                      <path d="M18.4,38h17.3c2.2,0,3.9,1.7,3.9,3.9v5H14.5v-5C14.5,39.8,16.3,38,18.4,38z"></path>
                      <line x1="19" y1="33.6" x2="35.2" y2="33.6"></line>
                    </g>
                    <g>
                      <g>
                        <path d="M47.2,89.2c5.6,0.3,6-7.1,11.6-6.8c5.6,0.3,5.2,7.7,10.9,8c5.6,0.3,6-7.1,11.7-6.8"></path>
                        <path d="M44.1,82.1c5.6,0.3,6-7.1,11.6-6.8"></path>
                      </g>
                      <g>
                        <g>
                          <path d="M54.5,49.3l-17.2,17c-0.8,0.8-1.2,1.8-1.1,2.9l0,0c0,0.2,0,0.4,0.1,0.6
					c0,0,0.4,1.3-0.1,1.8"></path>
                        </g>
                        <g>
                          <path d="M33.1,82.6c-0.9,1.4-1.7,2.8-1.7,3.9c-0.1,2.1,1.6,3.8,3.6,3.8c2.1,0.1,3.8-1.6,3.8-3.6
					c0-0.9-0.4-1.8-0.8-2.6c-0.8-1.6-2.4-3.2-2.5-5c0-0.2-0.1-0.5-0.1-0.7c0-0.5,0-1.1,0-1.6c0.1-0.4,0.2-1.1,0.5-1.7
					c0.1-0.2,0.4-0.8,1.1-1.2c0.5-0.3,1-0.6,1.6-0.6c0.8-0.1,1.6,0.2,2.4,0c0.7-0.1,1.4-0.5,2-1c5.7-5.7,11.4-11.3,17.2-17"></path>
                        </g>
                        <path d="M61.9,42l11.4-11.1c1.6-1.6,4.2-1.6,5.8,0l0,0c1.6,1.6,1.6,4.2,0,5.8L67.7,47.9"></path>
                        <path d="M57.6,42.6l9.5,9.6c1.2,1.2,1.2,3.1,0,4.3l-2.8,2.8L50.5,45.3l2.8-2.8C54.5,41.4,56.4,41.4,57.6,42.6z"></path>
                        <line x1="60.3" y1="40.4" x2="69.3" y2="49.4"></line>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <p className='mt-2 text-transform'>Oil Therapy</p>
            </Col>
            <Col className=''>
              <div className='services-svg' >
                <svg fill="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={98} viewBox="0 0 93.2 100.2" >
                  <g>
                    <path d="M18.4,54.8c-0.3-1.1-0.8-4.2,0.6-7.7c1.4-3.5,4-5.3,5-5.9"></path>
                    <path d="M51.6,81.8c-1.1,0.5-2.9,1.1-5.2,1.2c-2.3,0-4.2-0.6-5.3-1.2c0.3-0.4,0.6-0.8,0.9-1.2
		c0.4-0.5,0.9-0.9,1.4-1c0.1,0,0.3-0.1,0.6-0.1c1.2-0.1,1.4,0.4,2.4,0.4c0.9,0,1.2-0.4,2.3-0.3c0.3,0,0.5,0.1,0.7,0.2
		c0.5,0.2,0.9,0.5,1.2,0.9C51,81,51.3,81.4,51.6,81.8z"></path>
                    <path d="M42.4,85l1.3,1c0.4,0.2,0.9,0.4,1.4,0.4c0.1,0,0.2,0,0.3,0h2c0.2,0,0.4,0,0.6,0
		c0.5-0.1,0.9-0.2,1.3-0.5l1.1-0.9"></path>
                    <path d="M49.4,72.7c-0.1,0.3-0.3,0.4-1.3,1.1c-1,0.7-2.8,0.7-3.8,0c-1-0.7-1.3-0.8-1.3-1.1"></path>
                    <g>
                      <path d="M29,62.3c0.2-0.1,0.7-0.5,1.5-0.5c0.9,0,1.5,0.4,1.6,0.5c0.5,0.5,1.4,1.1,2.6,1.3
			c0.9,0.2,1.7,0.1,2.1,0.1c0.5,0,0.8-0.1,2.7-0.6c0.6-0.2,1.2-0.3,1.6-0.4"></path>
                      <path d="M32.3,64.8c0.4-1,1.6-1.2,1.9-1.3"></path>
                    </g>
                    <g>
                      <path d="M63.1,62.3c-0.2-0.1-0.7-0.5-1.5-0.5c-0.9,0-1.5,0.4-1.6,0.5c-0.5,0.5-1.4,1.1-2.6,1.3
			c-0.9,0.2-1.7,0.1-2.1,0.1c-0.5,0-0.8-0.1-2.7-0.6c-0.6-0.2-1.2-0.3-1.6-0.4"></path>
                      <path d="M57.9,63.5c0.3,0.1,1.5,0.3,1.9,1.3"></path>
                    </g>
                    <path d="M21.8,83.7c0.8-1.3,0.9-2.9,0.2-4.3c-0.5-0.9-1.3-1.7-1.9-2.5c-2.2-2.9-2.1-7.3,0.1-10.1
		c1-1.4,2.5-2.4,3.4-3.9"></path>
                    <path d="M68.4,63.5c-0.2,4.7-1,8.6-2,12.6c-1.9,7.1-10.5,14.1-16,17.9c-1.4,1-2.9,1.4-4.5,1.4h0.2
		c-1.6,0-3.1-0.5-4.5-1.4c-5.4-3.8-14.1-10.8-16-17.9c-1.1-4-1.9-8.5-2-13.2c-1.5-1.6-8.1-9.3-7.4-20.2c0.3-4.9,1.9-10.2,5.3-13.9
		c7.6-8.4,19.9-9.4,28-17"></path>
                    <path d="M41.1,56.4c0,0-1.2-1.6-4-2.1c-2.8-0.5-6.3,0-6.3,0"></path>
                    <path d="M49.6,56.4c0,0,1.2-1.6,4-2.1s6.3,0,6.3,0"></path>
                    <g>
                      <path d="M56.6,26.9c-2.2,4.1-5.5,7.4-9.2,10.1c-3.7,2.7-8,4.6-11.5,7.4c-2.2,1.7-6.5,5.1-9.5,11
			c-1.5,2.8-2.3,5.7-2.7,7.5"></path>
                      <path d="M48.5,40.2c1.9,0.7,3.9,1.7,6,3c9.2,6,12.6,16,13.9,20.2"></path>
                    </g>
                    <path d="M31.9,17.7C30.1,14.2,30.6,12,31,11c0.9-2.5,3.5-4,5.7-5.2c2.6-1.5,5.1-3.8,8.1-4.4
		c1.9-0.4,5.1-0.2,14.5,8c6.5,5.8,11.8,11.5,15,19.7c3,7.8,3.8,16.6,0.7,24.5c-2.2,5.6-6.3,9.6-6.3,9.6c-0.1,0.1-0.2,0.2-0.3,0.3"></path>
                    <path d="M37.9,28.8c3-1.8,5.9-3.7,8.6-6c2.6-2.2,4.2-4.6,5.7-7.6"></path>
                    <path d="M76,50.2c0,0,1.3-3.2-3.8-7.9"></path>
                    <path d="M35,89c0,0,2.9,6-0.5,10.1"></path>
                    <path d="M57.4,88.6c0,0-2.9,6,0.5,10.1"></path>
                  </g>
                </svg>
              </div>
              <p className='mt-2 text-transform'>Day Spa</p>
            </Col>
            <Col className=''>
              <div className='services-svg' >
                <svg fill="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={98} viewBox="0 0 93.2 100.2">
                  <g>
                    <g>
                      <path d="M39.7,40.4c0-5.2,6.8-5.2,6.8-10.3c0-5.2-6.8-5.2-6.8-10.3"></path>
                      <path d="M46.3,40.4c0-4.5,5.9-4.5,5.9-8.9"></path>
                    </g>
                    <path d="M50.4,40.4c7.9,0.2,15,0.6,20.4,1.3"></path>
                    <path d="M67.8,48.3c-5.1,0.5-11.3,0.9-18.2,1c-1.7,0-3.4,0-5.1,0c-20.1,0-36.4-2-36.4-4.5
		c0-1.2,3.9-2.3,10.4-3.1c4.6-0.6,10.3-1,16.8-1.2"></path>
                    <path d="M65.5,61.4c-8.4,0.8-15.6,1.1-21.4,1.1c-5,0-10-0.1-14.9-0.4c-3.7-0.2-7.4-0.5-11.1-0.9
		c-2.7-0.3-5.4-0.7-8.1-1c-1-0.1-2.6-0.5-3.2-0.1C6.3,60.5,6.1,61,6,61.6c-0.2,1.3-0.2,2.7,0.2,4c0.4,1.3,1.6,2.3,3,2.6
		c0.2,0,0.5,0.1,0.7,0.1"></path>
                    <path d="M9.8,68.3c2.9,0.3,6.9,0.7,11.7,1.1c6.5,0.5,14.4,1.1,24.3,1c5.2,0,12-0.3,20.1-1.1"></path>
                    <path d="M16.1,93.7c-0.2,0-0.5-0.1-0.7-0.1c-1.4-0.3-2.5-1.2-3-2.5c-0.5-1.3-0.5-2.6-0.3-4c0.1-0.6,0.3-1.1,0.8-1.5
		c0.3-0.3,0.9-0.3,1.5-0.2c0.6,0,1.2,0.1,1.7,0.2c0.9,0.1,1.7,0.1,2.6,0.3c1.8,0.3,13.1,1.4,27.4,1.3"></path>
                    <path d="M16.1,93.7c2.9,0.3,6.9,0.6,11.7,0.8c6.5,0.4,14.4,0.8,24.3,0.5"></path>
                    <path d="M14.3,85.4c-2.3-5.3-3.6-11.2-4.5-17.1"></path>
                    <path d="M8.7,60c0-0.7-0.4-16.4-1.7-23.7c-0.6-3.8-1.8-7.4-2.8-11.1c-1.1-3.9-1.7-8.6,0.1-12.5c1.2-2.5,4.1-4.7,7-3.9
		c5.8,1.6,7.2,10.1,6.8,15.1c-0.3,4.2-0.9,8.3-0.5,12.6"></path>

                    <ellipse transform="matrix(0.9958 -9.201155e-02 9.201155e-02 0.9958 -1.77 1.0721)" cx="10.7" cy="19.7" rx="2.4" ry="4.4"></ellipse>
                    <g>
                      <path d="M77.7,81.5c0.1,1.3-5.9,2.6-13.4,3c-7.5,0.3-13.5-0.5-13.6-1.8c-0.1-1.3,5.9-2.6,13.4-3
			c3.6-0.2,6.9-0.1,9.3,0.2"></path>
                      <path d="M50.7,82.7c0.3,7.5,6.6,13.2,14.1,12.9c7.5-0.3,13.2-6.6,12.9-14.1"></path>
                      <path d="M70.8,79.7c-0.3-7.5-0.8-26.4,2-32.8c1.3-3,3.5-5.2,6.3-6.3c3.9-1.5,8-0.4,9.2,0.1
			c1.7,0.8,2.5,2.8,1.7,4.5c-0.8,1.7-2.7,2.5-4.4,1.7c-0.6-0.2-4.7-1.6-6.6,2.6c-2,4.6-1.9,22.4-1.3,32.6c0,0.3,0,0.7-0.1,1"></path>
                    </g>
                  </g>
                </svg>
              </div>
              <p className='mt-2 text-transform'>Sauna</p>
            </Col>
            <Col className=''>
              <div className='services-svg' >
                <svg fill="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={98} viewBox="0 0 93.2 100.2">
                  <g>
                    <path d="M14.9,28"></path>
                    <line x1="62.9" y1="15.5" x2="63.2" y2="17.3"></line>
                    <path d="M42.1,42.4c-0.6-0.8-1.2-1.6-1.7-2.5c-4.4-7.8-3.5-19.9-13.1-24c0,0-2.7,6.8,1.8,14.7"></path>
                    <path d="M57.7,50.5c-1.6,2.8-3.9,5.4-6.6,7.1c-3.2,2-6.6,2.2-10.2,2.2c-1.7,0-3.4,0-5.1,0
		c-13-0.1-16.6,1-18.3-1.6c-1.5-2.2-0.2-7.7,0.8-10.1c-0.4-0.7-4.4-5.9-4.2-13.2c0-0.8,0.2-4.2,2.1-8.2c3.2-6.7,8.9-9.9,11.2-11"></path>
                    <path d="M23.6,18.1c-5.3,7.7-2.2,20,4.1,26.3"></path>
                    <path d="M37.2,30.9c2.1,1,4,2.7,5.5,4.5c3,3.7,3.6,8.1,4.9,12.6"></path>
                    <path d="M30.6,14.6c3.2-1,5.8-1.3,7.8-1.2c5.5,0.1,7.7,1.9,10.8,0.5c2-0.9,5.4-3.6,6.4-4
		c0.3-0.1,0.7-0.1,0.9,0.1c1.3,1.1,2.2,4,2.2,4l2,0c0.5,0,1,0.2,1.3,0.6l0.9,1.2c1.2-1.2,2.5,0,2.5,0l1.3,1.8l2.5,0.2
		c4,0.8,3.1,6,2.2,9.2c-0.4,1.5-0.9,3.1-1.2,4.7c-0.2,1,0.4,2.1,1.4,2.4c1.1,0.4,2.3,0.9,3.6,1.5c1.1,0.6,3.6,1.6,5.5,2.4
		c1.4,0.6,2.8,0.9,4.2,1.1l4.8,0.5"></path>
                    <g>
                      <path d="M23,76.2h26.8c0.9,0,1.6,0.7,1.6,1.6v2.9c0,0.9-0.7,1.6-1.6,1.6H16.6c-1.1,0-2-0.9-2-2v-5.3
			c0-2.1,1.7-3.8,3.8-3.8h33.8c2.1,0,3.8,1.7,3.8,3.8v7.8c0,2.1-1.7,3.8-3.8,3.8h-48c-0.8,0-1.1-1.1-0.4-1.5l6-4"></path>
                      <line x1="27.7" y1="64.1" x2="16.1" y2="71.8"></line>
                      <line x1="55.4" y1="84.7" x2="63.3" y2="75"></line>
                      <line x1="52.5" y1="71.1" x2="62.8" y2="62.2"></line>
                    </g>
                    <path d="M18.2,48.2l1.8,0.4c3.5,0.8,7,1.2,10.6,1.2h5.5"></path>
                    <path d="M53.8,48.2c0,0,6.2,3.2,7.3,3.8c4.8,2.5,8.4,6.9,7.9,10c-0.1,0.8-0.2,1.6-0.2,2.5c0.2,7,4.8,13.1,11.2,15.8
		c1.2,0.5,2.3,0.9,3.4,1.4"></path>
                    <path d="M69,62c0.7-1.6,2-4.1,4.6-6c0.9-0.7,2-1.2,3.1-1.6"></path>
                    <path d="M70.3,31.5c0,0-1.5,5.9-4.9,7.5"></path>
                  </g>
                </svg>
              </div>
              <p className='mt-2 text-transform'>Facials</p>
            </Col>
            <Col className=''>
              <div className='services-svg' >
                <svg fill="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={98} viewBox="0 0 93.2 100.2">
                  <g>
                    <g>
                      <path d="M35.2,25.3c-2,0.2-3-0.8-3.5-1.4c0,0,0,0,0,0"></path>
                      <path d="M41.2,15.8c0.1,0,1.5-0.8,2.9,0c1,0.6,1.3,1.7,1.3,2c0.1,0.6,0,1.4-0.4,2.2
			c-0.5,1-0.9,2.1-1,3.2c-0.1,1.3-0.2,3,0,4.3c0.2,0,0.5,0,0.8,0.1c0.4,0.1,0.6,0.3,0.8,0.4"></path>
                      <path d="M47.4,18.5c0.5,0,1,0.1,1.5,0.3c1,0.5,1.3,1.9,1.3,2c0,0.2,0.1,0.8-0.2,2
			c-0.5,1.7-1.2,2.1-1.6,3.4c-0.2,0.7-0.4,1.6-0.1,3c0.1,0,0.4,0.1,0.6,0.3c0.2,0.2,0.4,0.4,0.4,0.5"></path>
                      <path d="M52.1,22.7c0.5,0.1,1.1,0.3,1.4,0.7c0.3,0.3,0.4,0.7,0.5,0.9c0.4,1.2-0.1,2.3-0.7,3.4
			c-0.6,1.2-1,2.4-1.1,3.8c0.1,0,0.2,0.1,0.3,0.1c0.2,0.1,0.4,0.3,0.4,0.4"></path>
                      <path d="M41.2,26.6c-0.2-0.1-0.5-0.3-0.9-0.5c-0.8-0.3-1.3,0.1-1.5-0.2c-0.4-0.3,0.1-1.2,0.5-2.9
			c0,0,0.3-0.9,0.5-2.3c0.2-2.7-0.4-5.9-3.3-6.7c-2.3-0.6-4.9,0.4-5.5,2.8c-0.5,1.8-0.1,3.8,0.3,5.6c0.1,0.5,0.3,1,0.6,1.4
			c0,0,0,0.1,0.1,0.1c1,2,0.1,3.9,0.1,3.9c-3.6,6.2-1.7,12.6,0.5,18.8c0.5,1.4,1,2.7,1.4,4.1c0.3,1,0.6,2.1,0.9,3.1
			c0.4,1.4,0.7,2.8,0.9,4.2c0,0.2,0.1,0.5,0.1,0.7c0.3,2.3,0.2,4.5,0,6.6"></path>
                      <path d="M34.8,80.9c0.4,1,0.9,1.8,1.7,2.6c3.2,3.3,7.9,2.8,8.2,2.8c2.3-0.1,4.4-1.2,5.8-2.9
			c1.8-2.1,2.2-4.7,2.5-7.4c0.5-5.4,0.2-7.9,0.8-13.3"></path>
                      <path d="M56,48.1c0.6-3.5,0.5-3.2,1.4-6.7c0,0,0,0,0,0c0.6-2.7,0.1-5.5-1.5-7.8l0,0
			c-0.5-0.7,0.1-1.7,0.5-2.2c0.5-0.6,0.8-1.4,0.9-2.2c0-0.3,0-0.9-0.3-1.4c-0.3-0.5-0.7-0.7-0.9-0.8"></path>
                    </g>
                    <g>
                      <path d="M69.5,64.3C68.3,58,69.8,52,69.8,45.7l2.3-9.4c0.1-0.5-0.1-1-0.6-1.2c-0.6-0.3-1.2,0-1.5,0.6
			l-2.7,6"></path>
                      <path d="M57.3,41.3c0.5,0.9,0.9,1.6,1.4,2.9l-0.1,5.1c-0.1,1.3-1.1,2.4-2.4,2.5c-0.3,0-0.6,0-0.9,0
			c-0.2,0-0.4-0.1-0.7-0.2c-1-0.7-2-1.4-3-2.1c-1.5-1-3.3-2.8-5.2-1.8c-0.2,0.1-1.7,1.2-1.5,1.4c1.5,1.3,3.3,2.4,4.7,3.9
			c1.8,2,2.6,4.6,4.4,6.5c0.3,0.3,0.6,0.6,0.9,0.8c2.1,1.7,4.4,2.9,5.5,5.5"></path>
                      <polyline points="57.3,36.6 61.5,42.1 63.8,46.5 		"></polyline>
                      <polyline points="58,37.5 64,40 66.5,44.3 		"></polyline>
                    </g>
                    <g>
                      <path d="M34.7,54l-1.8,6.3l0.4,5.6c0.1,1.3,1.2,2.3,2.5,2.5c0.5,0.1,1.1,0,1.6-0.3
			c1-0.7,2-1.5,2.9-2.2c1.4-1.1,3.2-2.9,5.1-2c0.2,0.1,1.7,1.2,1.5,1.4c-1.6,1.5-3.7,2.9-5.1,4.6c-0.7,0.9-1.2,2-1.7,3
			c-0.9,1.6-1.8,3.2-3.2,4.4c-0.7,0.6-1.6,0.8-2.4,1.3c-1.3,0.9-2,2.3-2.6,3.8l-0.4,2"></path>
                      <path d="M33.8,51l-4.2,7.2L28,63.1c0,0-0.6,1.1-1.2,1.6"></path>
                      <path d="M23.1,83.3c0.2-0.7,0.4-1.5,0.6-2.6c0.7-4.1-0.1-11.4-0.4-14.3c-0.1-0.8,0-1.6,0.3-2.3
			l2.2-5.1c0.5-1,1.2-1.9,2.2-2.5l4.4-2.8"></path>
                    </g>
                    <path d="M42.8,52.6c-0.5-0.7-0.8-1.5-0.9-2.4c-0.3-3,1.8-5.7,4.8-6c0.7-0.1,1.3,0,2,0.1"></path>
                  </g>
                </svg>
              </div>
              <p className='mt-2 text-transform'>Massage</p>
            </Col>

          </Row>
        </Container>
      </section>
      <Footer />
    </>
  )
}

export default Services