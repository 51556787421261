import React, { useEffect } from 'react'
import {  useNavigate } from 'react-router-dom'
import { Footer, Header } from '../components'
import { PackagesCard } from '../components/Slider'
import { useDispatch, useSelector } from 'react-redux'
import { getPackage } from '../redux/packages'

function Packages() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allPackages = useSelector((state) => state.package.allPackages);
  function isObjectEmpty(data) {
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  useEffect(() => {

    if (allPackages && isObjectEmpty(allPackages)) {
      
      dispatch(getPackage());
    }
  }, [allPackages]);

 


  const handleBook = () => {
    navigate("/packages-details")
  }
  return (
    <>
    <Header />
    <section className='my-lg-20 my-10'>
    {/* <Container >
      <h2 className="h1 font-bolder mb-6">Packages</h2>
      <Row className='mb-8 align-items-center'>
        <Col lg={4}>
          <Card className='h-full bg-gray-200'>
            <CardBody className='p-0 overflow-hidden position-relative packages-card'>
              <Image src="https://i0.wp.com/letsrelaxspa.com/wp-content/uploads/2022/01/aromas-used-for-massages-2-scaled.jpg" className="image-effects rounded-top-2 transition-transform duration-500 transform position-absolute object h-full w-full image-hover-blur" alt="Hospitality Tourism" loading="lazy"/>
              <div className='position-relative overlap-10 ms-4 mt-32'> 
              <h3 className='mb-2 text-light'>Relax / Oil Massage</h3>
              <p className='mb-4 text-gray-200'>Use any tie-up spa 5 time</p>
              </div>
            </CardBody>
            <CardBody className='border-top pt-4'>
              <h4 className='mb-4'><MdCurrencyRupee />1200 / <span>60</span> minute</h4>
              <div className='text-center'><Button onClick={handleBook} variant="primary" size='sm' className="w-full">Book Now</Button></div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={4}>
          <Card className='h-full bg-gray-200'>
            <CardBody className='p-0 overflow-hidden position-relative packages-card'>
              <Image src="https://i0.wp.com/letsrelaxspa.com/wp-content/uploads/2022/01/aromas-used-for-massages-2-scaled.jpg" className="image-effects rounded-top-2 transition-transform duration-500 transform position-absolute object h-full w-full image-hover-blur" alt="Hospitality Tourism" loading="lazy"/>
              <div className='position-relative overlap-10 ms-4 mt-32'> 
              <h3 className='mb-2 text-light'>Relax / Oil Massage</h3>
              <p className='mb-4 text-gray-200'>Use any tie-up spa 5 time</p>
              </div>
            </CardBody>
            <CardBody className='border-top pt-4'>
              <h4 className='mb-4'><MdCurrencyRupee />1200 / <span>60</span> minute</h4>
              <div className='text-center'>
                <Button onClick={handleBook} variant="primary" size='sm' className="w-full">Book Now</Button></div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={4}>
          <Card className='h-full bg-gray-200'>
            <CardBody className='p-0 overflow-hidden position-relative packages-card'>
              <Image src="https://i0.wp.com/letsrelaxspa.com/wp-content/uploads/2022/01/aromas-used-for-massages-2-scaled.jpg" className="image-effects rounded-top-2 transition-transform duration-500 transform position-absolute object h-full w-full image-hover-blur" alt="Hospitality Tourism" loading="lazy"/>
              <div className='position-relative overlap-10 ms-4 mt-32'> 
              <h3 className='mb-2 text-light'>Relax / Oil Massage</h3>
              <p className='mb-4 text-gray-200'>Use any tie-up spa 5 time</p>
              </div>
            </CardBody>
            <CardBody className='border-top pt-4'>
              <h4 className='mb-4'><MdCurrencyRupee />1200 / <span>60</span> minute</h4>
              <div className='text-center'><Button onClick={handleBook} variant="primary" size='sm' className="w-full">Book Now</Button></div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container> */}
    <PackagesCard data={allPackages} MorePackagesButton={false}/>
  </section>
  <Footer />
  </>
  )
}

export default Packages;