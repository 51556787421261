import React, { useEffect, useState } from "react";
import { Footer, Header } from "../components";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Image,
  Row,
  Table,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getUserReferData } from "../redux/user";
import { useNavigate } from "react-router";
import { ShareLinkPhotos } from "../assets/images";
import { MdCheck, MdContentCopy } from "react-icons/md";

function Ref() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.user.userDetails);
  const getUserReferList = useSelector((state) => state.user.getUserReferList);

  useEffect(() => {
    if (!getUserReferList || getUserReferList.length === 0) {
      dispatch(getUserReferData());
    }
  }, [getUserReferList]);

  const handleUserRefUse = (id) => {
    navigate(`/ref/scanner/${id}`);
  };

  const [copied, setCopied] = useState(false);

  const referralLink = `${process.env.REACT_APP_LINK}ref/${userDetails?.user?._id}`;

  const handleCopy = () => {
    navigator.clipboard.writeText(referralLink);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
  };

  const handleLogin = () => {
    navigate("/login");
  };
  return (
    <div>
      <Header />
      <h1 className="mb-10 text-center text-light bg-primary py-6">
        Refer a Friend – Share Wellness, Earn Rewards!
      </h1>
      <Container className="py-6 gx-md-12 mb-lg-20 mb-10">
        {/* <h2 className="text-center max-w-screen-lg mx-auto mb-6">
          Invite Your Friends to TherapyZone and Earn Free Therapy Sessions!
        </h2> */}
        <p className="text-center max-w-screen-lg mx-auto mb-1 text-lg">Welcome to TherapyZone’s Referral Program, where sharing wellness with your friends and family comes with exciting rewards! It’s simple: share your unique referral code, and when your friends join TherapyZone and purchase a subscription package, you both earn.</p>
      </Container>
      <Container className="mb-lg-20 mb-10">
        <h2 className="text-center">How It Works</h2>
        <p className="mb-6 text-md text-center">It’s Simple to Start Sharing!</p>
        <Row className="gy-6">
          <Col lg={4} md={6}>
            <Card className="bg-gray-200 h-full">
              <CardBody>
                <h5 className="mb-2 text-lg">Share Your Code</h5>
                <p className="text-md">
                After signing up, you’ll receive a unique referral code. Share this code with your friends and family.
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4} md={6}>
            <Card className="bg-gray-200 h-full">
              <CardBody>
                <h5 className="mb-2 text-lg">
                  Friends Sign Up and Purchase a Package
                </h5>
                <p className="text-md">
                When your friends sign up using your referral code and purchase a subscription package, you earn rewards.
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4} md={12}>
            <Card className="bg-gray-200 h-full">
              <CardBody>
                <h5 className="mb-2 text-lg">Rewards Multiply</h5>
                <p className="text-md">
                The rewards don’t stop there! When your friends refer others, you continue to earn.
                </p>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container className="mb-lg-20 mb-10">
        <h2 className="text-center">Rewards Structure</h2>
        <p className="mb-6 text-md text-center">Here’s how the rewards stack up for every successful referral:</p>
        <Card className="bg-gray-200 h-full">
              <CardBody>
              <div className="bg-white py-3 px-3 mb-2 rounded-2">
                <p className="text-md">
                <span className="font-bold text-dark">Direct Referral:</span> When your friend (Level 1) joins and purchases a package, you earn ₹200.
                </p>
              </div>
              <div className="bg-white py-3 px-3 mb-2 rounded-2">
                <p className="text-md">
                <span className="font-bold text-dark">Second Level Referral:</span> If your friend refers someone else (Level 2) who purchases a package, you earn ₹125, and your friend earns ₹200.
                </p>
              </div>
              <div className="bg-white py-3 px-3 mb-2 rounded-2">
                <p className="text-md">
                <span className="font-bold text-dark">Third Level Referral:</span> When Level 2 refers someone (Level 3), you earn ₹75, Level 2 earns ₹125, and Level 1 earns ₹200.
                </p>
              </div>
              <div className="bg-white py-3 px-3 mb-2 rounded-2">
                <p className="text-md">
                <span className="font-bold text-dark">Fourth Level Referral:</span> When Level 3 refers someone (Level 4), you earn ₹50, and the rewards continue to flow up the chain.
                </p>
              </div>
              <div className="bg-white py-3 px-3 mb-2 rounded-2">
                <p className="text-md">
                <span className="font-bold text-dark">Fifth Level Referral:</span> At this level, you earn ₹25 for each successful referral in your network.
                </p>
              </div>
              </CardBody>
            </Card>
      </Container>
      <section className="bg-gray-200 py-lg-20 py-10">
        <Container className="">
          <Row className="align-items-center gy-6">
            <Col lg={6} className="order-lg-1 order-2">
              <h2 className="mb-3">Start Referring Today!</h2>
              <p>If you are not logged in, the referral code input field will be hidden. Please log in to access and share your unique referral code.</p>
              {localStorage.getItem("therapyToken") &&
              localStorage.getItem("roles") === "user" ? (
                <>
                  <h4 className="mb-3 text-gray-800">
                    Share this link with new users
                  </h4>
                  <Card className="border-primary border d-flex flex-row overflow-hidden">
                    <Col className="bg-orange-100">
                      <CardBody className="py-3 px-4 d-flex">
                        <span className="text-dark text-truncate d-inline-block w-40">
                          {referralLink}
                        </span>
                      </CardBody>
                    </Col>
                    <Col className="col-auto bg-primary">
                      <CardBody className="py-3 px-4 w-auto">
                        {copied ? (
                          <MdCheck className="text-light" />
                        ) : (
                          <MdContentCopy
                            className="text-light"
                            onClick={handleCopy}
                          />
                        )}
                        {/* <MdContentCopy className="text-light" onClick={handleCopy}/> */}
                      </CardBody>
                    </Col>
                  </Card>
                </>
              ) : (
                <div>
                  <p className="mb-4 text-md font-semibold">Login to Access Your Referral Code</p>
                <Button
                  variant="primary"
                  className="px-6 mb-6"
                  onClick={handleLogin}
                >
                  Log in
                </Button>
                </div>
              )}
              <p className="text-md font-italic"><span className="font-semibold text-dark">Note:</span> First, log in to your account and then share your referral code with your friends and family.</p>
            </Col>
            <Col lg={6} className="order-lg-2 order-1">
              <Image src={ShareLinkPhotos} className="rounded-4" />
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </div>
  );
}

export default Ref;
