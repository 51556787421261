import React, { useEffect, useState } from "react";
import { Footer, Header } from "../components";
import { Badge, Card, Container, Table } from "react-bootstrap";
import axios from "axios";
function RefList({ userId }) {
  const [referralData, setReferralData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch the referral tree from the backend
    const fetchReferralTree = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("therapyToken")}`,
          },
        };
        const response = await axios.get(
          `${process.env.REACT_APP_API_KEY}user/referrals`,
          config
        );
        setReferralData(response?.data?.referralTree);
        setLoading(false);
      } catch (err) {
        setError("Failed to load referral data.");
        setLoading(false);
      }
    };

    fetchReferralTree();
  }, [userId]);

  // Function to recursively render referral data in table rows
  const renderReferralTree = (referrals) => {
    if (!referrals || referrals.length === 0) return null;

    return referrals.map((referral, index) => {
      const { ref_id, amount, level } = referral; // Extract relevant data

      return (
        <tr key={index}>
          <td>{ref_id.name}</td> {/* Display the user's name */}
          <td>{amount}</td> {/* Display the referral amount */}
          <td>{`Level ${level}`}</td> {/* Display the level */}
        </tr>
      );
    });
  };

  if (loading) {
    return <div>Loading referral data...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <Header />

      <h1 className="mb-10 mt-10 text-center py-6">Reference List</h1>
      <Container>
        {referralData?.ref && (
          <Card>
            <Card.Body className="p-0">
              <Table
                responsive
                striped
                bordered
                hover
                className="table-design mb-5"
              >
                <thead>
                  <tr>
                    <th>User</th>
                    <th>Amount</th>
                    <th>Level</th>
                  </tr>
                </thead>
                <tbody>{renderReferralTree(referralData?.ref)}</tbody>
              </Table>
            </Card.Body>
          </Card>
        )}
      </Container>
      <Footer />
    </div>
  );
}

export default RefList;

// import React, { useState, useEffect } from "react";
// import axios from "axios";

// const RefList = ({ userId }) => {
//   const [referralData, setReferralData] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     // Fetch the referral tree from the backend
//     const fetchReferralTree = async () => {
//       try {
//         const config = {
//           headers: {
//             Authorization: `Bearer ${localStorage.getItem("therapyToken")}`,
//           },
//         };
//         const response = await axios.get(
//           `${process.env.REACT_APP_API_KEY}user/referrals`,
//           config
//         );
//         setReferralData(response.data.referralTree);
//         setLoading(false);
//       } catch (err) {
//         setError("Failed to load referral data.");
//         setLoading(false);
//       }
//     };

//     fetchReferralTree();
//   }, [userId]);

//   // Function to recursively render referral data in table rows
//   const renderReferralTree = (referrals) => {
//     if (!referrals || referrals.length === 0) return null;

//     return referrals.map((referral, index) => {
//       const { ref_id, amount, level } = referral; // Extract relevant data

//       return (
//         <tr key={index}>
//           <td>{ref_id.name}</td> {/* Display the user's name */}
//           <td>{amount}</td> {/* Display the referral amount */}
//           <td>{`Level ${level}`}</td> {/* Display the level */}
//         </tr>
//       );
//     });
//   };

//   if (loading) {
//     return <div>Loading referral data...</div>;
//   }

//   if (error) {
//     return <div>{error}</div>;
//   }

//   return (
//     <div>
//       <h2>Referral Tree for {referralData.user_id?.name || "User"}</h2>
//       <table border="1" cellPadding="10" cellSpacing="0">
//         <thead>
//           <tr>
//             <th>User</th>
//             <th>Amount</th>
//             <th>Level</th>
//           </tr>
//         </thead>
//         <tbody>
//           {renderReferralTree(referralData.ref)}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default RefList;
