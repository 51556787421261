const api_domain = `${process.env.REACT_APP_API_KEY}`;
// const api_domain = "https://dev.tapgro.com/";

const api_host = api_domain + "";
const api_endpoints = {
  packagesListGet: "user/package",
  spauserDetailData:"spa/owner",
  activeSpaList:"spa",
  loginApi: "user/login",
  spaLoginApi: "spa/login",
  packagesListAdd:"user/package",
  createQr:"qrcode",
  bookSpa:"spa/book",
  getuserBookSpa:"spa/user/book",
  getAdminBookSpa:"spa/admin/book",
  adminLoginApi:"admin/login",
  getAllSpa:"admin/getspa",
  updateUserProfile:"user/update/",
  updateSpaProfile:"spa/update/",
  getAllBookSpa:"admin/getbookspa",
  getUserRefer:"user/reflist",
  refAddBookSpa:"user/ref/book",
  packageListTypeGet:"admin/typeofspa",
  packageAddTypeAdd:"admin/typeofspa",
};

export { api_host, api_endpoints, api_domain };
