import React, {  useState } from "react";
import { Button, Col, Form, Row,  } from "react-bootstrap";
import {  addPackageType } from "../../redux/packages";
import { useDispatch } from "react-redux";

function AddPackagesTypeForm() {
  const dispatch = useDispatch();
 
  const [formData, setFormData] = useState({
    nameOfType: "",
  });
  const [error, setError] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setError((pre) => ({ ...pre, [name]: "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};

    // Validation
    if (!formData.nameOfType)
      newErrors.nameOfType = "Package name is required.";

    setError(newErrors);

    // Only proceed if there are no errors
    if (Object.keys(newErrors).length === 0) {
      // Make your API call here
      dispatch(addPackageType(formData)) 
      // Reset form and preview if desired
    }
  };

  return (
    <Form noValidate onSubmit={handleSubmit} className="vstack gap-4">
      <Row className="gy-6">
        <Col md={6}>
          <Form.Group controlId="nameOfType">
            <Form.Label>Package name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Package name"
              name="nameOfType"
              value={formData.nameOfType}
              onChange={(e)=>handleChange(e)}
              isInvalid={!!error.nameOfType} // Display error if exists
            />
            <Form.Control.Feedback type="invalid">
              {error.nameOfType}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
       
      </Row>
      <Button type="submit" className="mt-3">
        Submit
      </Button>
    </Form>
  );
}

export default AddPackagesTypeForm;
