import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Image,
  Modal,
  Row,
  Form,
} from "react-bootstrap";
import { MdArrowOutward, MdCurrencyRupee } from "react-icons/md";
import { cashfree } from "../../util/cashFree";
import { useDispatch, useSelector } from "react-redux";
import { userDetailData } from "../../redux/user";
import axios from "axios";

function PackagesCard({ data, MorePackagesButton, userId }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [referralData, setReferralData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalSum, setTotalSum] = useState(0);
  const [originalPriceData, setoriginalPriceData] = useState(0);
  const [show, setShow] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null); // Selected package
  const [isChecked, setIsChecked] = useState(false); // Checkbox for applying totalSum deduction

  const userDetails = useSelector((state) => state.user.userDetails);

  useEffect(() => {
    if (
      localStorage.getItem("therapyToken") &&
      localStorage.getItem("roles") === "user"
    ) {
      if (!userDetails.user) {
        dispatch(userDetailData());
      }
    }
  }, [userDetails]);

  const getSessionId = async (item) => {
    const body = {
      packageDetails: {
        item,
      },
      userDetails: {
        user: userDetails.user,
      },
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}payment/paymentsdata`,
        body
      );

      return response.data;
    } catch (error) {
      console.error(error, "Error on getSessionId");
    }
  };

  const handlePayments = async (item) => {
    console.log("🚀 ~ handlePayments ~ item:", item);

    if (localStorage.getItem("roles") !== "user") {
      return navigate("/login");
    }

    let sessionId = await getSessionId(item);

    let checkoutOptions = {
      paymentSessionId: sessionId,
      returnUrl: `${process.env.REACT_APP_API_KEY}payment/status/{order_id}`,
    };

    cashfree.checkout(checkoutOptions).then(function (result) {
      if (result.error) {
        alert(result.error.message);
      }
      if (result.redirect) {
        // alert("Redirection");
        console.log("Redirection");
        
      }
    });
  };

  // Fetch the referral tree from the backend
  useEffect(() => {
    const fetchReferralTree = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("therapyToken")}`,
          },
        };
        const response = await axios.get(
          `${process.env.REACT_APP_API_KEY}user/referrals`,
          config
        );

        const { ref } = response.data.referralTree;

        // Calculate the total amount from the new API structure
        let total = 0;
        ref.forEach((referral) => {
          if (!referral.used) {
            total += referral.amount;
          }
        });

        setReferralData(response.data.referralTree);
        setTotalSum(total); // Set the sum of unused amounts
        setLoading(false);
      } catch (err) {
        // setError("Failed to load referral data.");
        setLoading(false);
      }
    };

    fetchReferralTree();
  }, [userId]);

  const handleShowModal = (item) => {
    setSelectedPackage(item); // Set selected package details
    setoriginalPriceData(item.prices);
    setShow(true); // Show modal
  };

  const handleCloseModal = () => {
    setShow(false);
    setIsChecked(false); // Reset checkbox
  };

  const handleCheckboxChange = () => {
    // Toggle the checkbox and update the selectedPackage's price accordingly
    setIsChecked(!isChecked);

    // Save the original price in a separate variable
    const originalPrice = selectedPackage.prices;

    let newPrice = selectedPackage.prices;

    if (!isChecked) {
      // Deduct totalSum from package price when checked, ensuring it doesn't go negative
      newPrice = selectedPackage.prices - totalSum;
      if (newPrice < 0) {
        newPrice = 10; // Set price to 0 if it goes negative
      }
    } else {
      // Restore the original price when unchecked
      newPrice = originalPriceData;
    }

    // Update the selectedPackage price
    setSelectedPackage((prevState) => ({
      ...prevState,
      prices: newPrice,
    }));
  };

  return (
    <>
      <section className="mb-lg-20 mb-10">
        <Container>
          <h2 className="h1 font-bolder mb-6">Packages</h2>
          <Row className="mb-8 gy-6 align-items-center">
            {data &&
              data?.data?.map((item, index) => {
                return (
                  <Col lg={4} key={index}>
                    <Card className="h-full bg-gray-200">
                      <CardBody className="p-0 overflow-hidden position-relative packages-card">
                        <Image
                          src={item.image}
                          className="image-effects rounded-top-2 transition-transform duration-500 transform position-absolute object h-full w-full image-hover-blur"
                          alt="Hospitality Tourism"
                          loading="lazy"
                        />
                        <div className="position-relative overlap-10 ms-4 mt-32">
                          <h3 className="mb-2 text-light">{item.title}</h3>
                          <p className="mb-4 text-gray-200">
                            {item.time} months validity
                          </p>
                        </div>
                      </CardBody>
                      <CardBody className="border-top pt-4">
                        <h4 className="mb-4">
                          <MdCurrencyRupee />
                          {item.prices} / <span>{item.description}</span>
                        </h4>
                        <div className="text-center">
                          <Button
                            variant="primary"
                            size="sm"
                            className="w-full"
                            onClick={() => handleShowModal(item)} // Show modal on click
                          >
                            Book Now
                          </Button>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
          </Row>

          {MorePackagesButton && (
            <div className="text-center">
              <Button
                variant="dark"
                as={Link}
                to="./Packages"
                size="lg"
                className="btn-animation"
              >
                <span>More Packages</span>{" "}
                <MdArrowOutward className="button-animation-icon" />
              </Button>
            </div>
          )}
        </Container>
      </section>

      {/* Modal to display package details */}
      <Modal show={show} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Payment Modal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <strong>Package Name:</strong> {selectedPackage?.title}
          </p>
          <p>
            <strong>Package Price:</strong> {selectedPackage?.prices}
          </p>
          <p>
            <strong>Referral Balance:</strong> {totalSum}
          </p>

          <Form.Check
            type="checkbox"
            label="Apply Referral Bonus to Package Price"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />

          <p>
            <strong>Final Price: </strong> {selectedPackage?.prices}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => handlePayments(selectedPackage)}
          >
            Proceed to Payment
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PackagesCard;
