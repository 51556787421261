import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Container, Button, Modal, Card } from "react-bootstrap";
import Webcam from "react-webcam";
import jsQR from "jsqr";
import { useDispatch, useSelector } from "react-redux";
import {  refBookspaAdd } from "../redux/bookspa";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router";
import {  Footer, Header } from "../components";
import { getUserReferData } from "../redux/user";

function RefScanner() {
  const { usecodeid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bookspa = useSelector((state) => state.bookspa.refBookspa);

  const options = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progressStyle: { background: "#F54E15" },
    progress: undefined,
    theme: "dark",
  };

  useEffect(() => {
    if (bookspa.message) {
      toast.error(bookspa.message, options);
    }
    if (bookspa.spaData) {
      toast.success("Your Appointment Confirmed", options);
      dispatch(getUserReferData());
      navigate("/ref");
    }
  }, [bookspa]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [data, setData] = useState("");
  const [camera, setCamera] = useState("environment");
  const webcamRef = useRef(null);
  const [scanning, setScanning] = useState(true); // Flag to control scanning

  useEffect(() => {
    if (scanning) {
      const intervalId = setInterval(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        if (imageSrc) {
          const img = new Image();
          img.src = imageSrc;
          img.onload = () => {
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            canvas.height = img.height;
            canvas.width = img.width;
            context.drawImage(img, 0, 0, img.width, img.height);
            const imageData = context.getImageData(0, 0, img.width, img.height);
            const code = jsQR(imageData.data, img.width, img.height);
            if (code) {
              setData(code.data);
              setScanning(false);
              handleShow();
            }
          };
        }
      }, 1000); // Scan every 1000 ms (1 second)

      return () => clearInterval(intervalId); // Clean up on component unmount
    }
  }, [scanning]);

  const toggleCamera = () => {
    setCamera((prevCamera) =>
      prevCamera === "environment" ? "user" : "environment"
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (data) {
      dispatch(
        refBookspaAdd({
          spaId: data,
          usecode:usecodeid
        })
      );
      handleClose();
    }
  };

  return (
    <>
      <div className="">
        <Header/>
      {/* <div className="d-flex flex-column flex-lg-row h-lg-full min-h-screen bg-gray-200 vstack"> */}
        {/* <AdminSidebar /> */}
        {/* <div className="flex-lg-1 h-lg-screen overflow-y-lg-auto"> */}
          {/* <AdminHeader title={"Dashboard"} /> */}
          <Container fluid className="py-6 gx-md-12">
            <Row>
              <Card>
                <Col>
                  <div>
                    <h1>QR Code Scanner</h1>
                    <h2>Reference : {data}</h2>
                    <Webcam
                      audio={false}
                      ref={webcamRef}
                      screenshotFormat="image/jpeg"
                      width="100%"
                      height="100%"
                      videoConstraints={{ facingMode: camera }}
                    />
                    <Button onClick={toggleCamera}>
                      Switch to {camera === "environment" ? "Front" : "Back"}
                      Camera
                    </Button>
                  </div>
                </Col>
              </Card>
            </Row>
          </Container>
        {/* </div> */}
        <Footer/>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Appointment Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Your Appointment is Confirmed!</h4>
          {/* <p>Details:</p> */}
          {/* <p>{appointmentData}</p> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RefScanner;
