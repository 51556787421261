import React, { useRef, useState } from 'react';
import { Button, Card, CardBody, Col, Container, Image, Row, Stack } from 'react-bootstrap'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';

// import './styles.css';

import { FaChevronLeft, FaChevronRight } from "react-icons/fa";




function TypeOfSpa() {

  return (
    <>
      <Container className='mb-lg-20 mb-10'>
        <Row className='mb-8 align-items-center'>
          <Col className='col-8'>
            <h2 className="h1 font-bolder">Type of Spa</h2>
          </Col>
          <Col className='col-4'>
            <Stack gap={2} direction="horizontal">
              <Button className="type-of-spa-prev swiper-button w-10 h-10 d-flex justify-content-center align-items-center ms-auto rounded-circle"><FaChevronLeft className='text-light text-lg' /></Button>
              <Button className="type-of-spa-next w-10 h-10 d-flex justify-content-center align-items-center rounded-circle"><FaChevronRight className='text-light' /></Button>
            </Stack>
          </Col>
        </Row>
        <Swiper
          modules={[Navigation]} className="type-of-spa-slider slider-card-height-fix"
          spaceBetween={24}
          navigation={{
            prevEl: '.type-of-spa-prev',
            nextEl: '.type-of-spa-next',
          }}
          slidesPerView={3}
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
        >
          <SwiperSlide>
            <Card className='bg-gray-200 h-full'>
              <Image className="card-img-top h-72 object-fit-cover" width="423" height="288" src="https://www.juaraskincare.com/cdn/shop/articles/what-is-aromatherapy-massage.jpg?v=1711678233" />
              <CardBody>
                <h4>Aroma Massage</h4>
              </CardBody>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card className='bg-gray-200 h-full'>
              <Image className="card-img-top h-72 object-fit-cover" width="423" height="288" src="https://www.juaraskincare.com/cdn/shop/articles/what-is-aromatherapy-massage.jpg?v=1711678233" />
              <CardBody>
                <h4>slider Massage</h4>
              </CardBody>
            </Card>
          </SwiperSlide>
          <SwiperSlide>Slide 2</SwiperSlide>
          <SwiperSlide>Slide 3</SwiperSlide>
          <SwiperSlide>Slide 4</SwiperSlide>
        </Swiper>

        
      </Container>
    </>
  )
}

export default TypeOfSpa;