import React, { useEffect, useState } from "react";
import { AdminHeader, AdminSidebar } from "../../components";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  Modal,
  Table,
} from "react-bootstrap";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getAllBookSpaData } from "../../redux/bookspa";
import axios from "axios";
import ReactPaginate from "react-paginate";

function AdminBookSpa() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getAllBookSpa = useSelector((state) => state.bookspa.getAllBookSpa);

  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [spaId, setSpaId] = useState("");
  const [currentPage, setCurrentPage] = useState(0); // Current page
  const itemsPerPage = 5; // Items per page

  const handleClose = () => setShow(false);
  const handleShow = (spaid) => {
    if (spaid) setSpaId(spaid);
    setShow(true);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/admin/login");
  };

  useEffect(() => {
    if (getAllBookSpa.length === 0) {
      dispatch(getAllBookSpaData());
    }
  }, [getAllBookSpa]);

  useEffect(() => {
    if (getAllBookSpa && getAllBookSpa?.length !== 0) {
      setData(getAllBookSpa);
    }
  }, [getAllBookSpa]);

  const handleSubmit = async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_KEY}admin/updateactive/${spaId}`
      );
      if (response.status === 200) {
        dispatch(getAllBookSpaData());
        handleClose();
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  // Pagination Logic
  const offset = currentPage * itemsPerPage;
  const currentPageData = data.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(data.length / itemsPerPage);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handlePaymentUpdate = async (id) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_KEY}admin/updatebookspapayment/${id}`
      );
      if (response.status === 200) {
        dispatch(getAllBookSpaData());
        handleClose();
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };
  return (
    <>
      <div>
        <div className="d-flex flex-column flex-lg-row h-lg-full min-h-screen bg-gray-200 vstack">
          <AdminSidebar handleLogout={handleLogout} />
          <div className="flex-lg-1 h-lg-screen overflow-y-lg-auto">
            <AdminHeader title={"QR Code"} handleLogout={handleLogout} />
            <Container fluid className="py-6 gx-md-12">
              <Card>
                <CardHeader className="border-bottom">
                  <h4>User Book Spa</h4>
                </CardHeader>
                <CardBody className="p-0">
                  <Table
                    responsive
                    striped
                    bordered
                    hover
                    className="table-design"
                  >
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>User Name</th>
                        <th>User Number</th>
                        <th>Spa Name</th>
                        <th>Spa Number</th>
                        <th>UseCode</th>
                        <th>Payment Status</th>
                        <th>Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentPageData.map((item, index) => {
                        const createdAtDate = new Date(item.createdAt);

                        // Format date
                        const options = {
                          weekday: "long",
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: false,
                        };
                        const formattedDate = createdAtDate
                          .toLocaleDateString("en-GB", options)
                          .replace(",", "")
                          .replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3");

                        return (
                          <tr key={index}>
                            <td>{offset + index + 1}</td>
                            <td>{item.customerId.name}</td>
                            <td>{item.customerId.phone_no}</td>
                            <td>{item.spaId.name}</td>
                            <td>{item.spaId.phone_no}</td>
                            <td>{item.useCodeId}</td>
                            <td>
                              {item.paymentSatus === "false" ? (
                                <Button
                                  type="submit"
                                  className="mt-3 "
                                  onClick={() => handlePaymentUpdate(item._id)}
                                >
                                  pending
                                </Button>
                              ) : (
                                "success"
                              )}
                            </td>
                            <td>{formattedDate}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
                <CardFooter>
                  <div className="d-flex justify-content-between align-items-center">
                    <p>
                      Showing {offset + 1} to {offset + currentPageData.length}{" "}
                      of {data.length} entries
                    </p>

                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </CardFooter>
              </Card>
            </Container>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Spa Active Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>You Are Confirmed! Spa Active</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AdminBookSpa;
