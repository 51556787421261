import React, { useEffect } from "react";
import { Navbar, Container, Dropdown, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { spaownerDetailData } from "../../redux/user";

function AdminHeader({ title }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const spaownerDetails = useSelector((state) => state.user.spaownerDetails);

  useEffect(() => {
    if (
      localStorage.getItem("therapyToken") &&
      localStorage.getItem("roles") === "spa_owner"
    ) {
      if (!spaownerDetails.user) {
        dispatch(spaownerDetailData());
      }
    }
  }, [spaownerDetails, dispatch]);

  const handleLogout = () => {
    localStorage.clear();
    navigate("/spa/login");
  };
  return (
    <Navbar
      bg="white"
      className="position-lg-sticky top-lg-0 d-none d-lg-block overlap-10 flex-none border-bottom px-0 py-3"
      id="topbar"
    >
      <Container fluid className="gx-12">
        <Navbar.Text className="text-capitalize">
          <div className="page-title d-flex">
            <h5>
              <span className="font-weight-semibold">{title}</span>
            </h5>
          </div>
        </Navbar.Text>

        <div className="ms-auto d-none d-sm-block">
          <div className="hstack gap-3 ms-4">
            <Dropdown>
              <Dropdown.Toggle
                as="a"
                className="d-flex align-items-center"
                id="dropdown-basic"
              >
                <Image
                  className="avatar avatar-sm rounded-circle border object"
                  src={
                    spaownerDetails?.user?.profile_photo
                      ? spaownerDetails?.user?.profile_photo
                      : "https://beta.techvoot.com/storage/users/291106_163637753912.jpeg"
                  }
                  alt="Admin"
                />
                <div className="d-none d-sm-block ms-3">
                  <span className="h6 d-block">
                    {spaownerDetails?.user?.name}
                  </span>
                  <span className="text-xs text-muted d-block">spa owner</span>
                </div>
                <i className="bi bi-chevron-down text-muted text-xs d-none d-md-block ms-md-2"></i>
              </Dropdown.Toggle>

              <Dropdown.Menu align="end">
                <Dropdown.Item to={"/admin/profile"} as={Link}>
                  <i className="bi bi-person-vcard me-3"></i>Profile
                </Dropdown.Item>
                {/* <Dropdown.Item href="https://beta.techvoot.com/generate-sitemap">
                  <i className="bi bi-file-earmark-plus me-3"></i>Generate a New
                  Sitemap
                </Dropdown.Item> */}
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => handleLogout()}>
                  <i className="bi bi-box-arrow-left me-3"></i>Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </Container>
    </Navbar>
  );
}

export default AdminHeader;
