import React, { useEffect, useState } from "react";
import {
  Navbar,
  Container,
  Nav,
  Image,
  Stack,
  Dropdown,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./header.scss";
import { logo, tempProfile } from "../../assets/images";
import { FaBars, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { userDetailData } from "../../redux/user";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [data, setData] = useState({});

  const userRole = useSelector((state) => state.user.userRole);
  const userDetails = useSelector((state) => state.user.userDetails);

  useEffect(() => {
    if (userDetails) setData(userDetails.user);
  }, [userRole, userDetails]);

  useEffect(() => {
    if (
      localStorage.getItem("therapyToken") &&
      localStorage.getItem("roles") === "user"
    ) {
      if (!userDetails.user) {
        dispatch(userDetailData());
      }
    }

    if (
      localStorage.getItem("therapyToken") &&
      localStorage.getItem("roles") === "spa_owner"
    ) {
      navigate("/admin/package");
    }
    if (
      localStorage.getItem("therapyToken") &&
      localStorage.getItem("roles") === "admin"
    ) {
      navigate("/admin/dashboard");
    }
  }, [userDetails]);

  const handleLogut = () => {
    navigate("/login");
    localStorage.clear();
  };
  return (
    <>
      <Navbar
        expand="lg"
        className="py-0 tv-header border-bottom"
        bg="light"
        data-bs-theme="light"
      >
        <Container>
          <Navbar.Brand as={Link} to="/" className="py-4">
            <Image width={200} src={logo} alt="therapy zone" />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setExpanded(expanded ? false : "expanded")}
            className="custom-toggler text-primary shadow-none"
          >
            {expanded ? <FaTimes /> : <FaBars />} {/* Toggle between icons */}
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav" className="pb-lg-0 pb-4">
            <Nav className="ms-auto me-lg-4 mb-lg-0 mb-4">
              <Nav.Link className="py-0 my-2 px-lg-4 px-0" as={Link} to="/">
                <span>Home</span>
              </Nav.Link>
              <Nav.Link
                className="py-0 my-2 px-lg-4 px-0"
                as={Link}
                to="/about-us"
              >
                <span>About Us</span>
              </Nav.Link>
              {/* <Nav.Link
                className="py-0 my-2 px-lg-4 px-0"
                as={Link}
                to="/services"
              >
                <span>Services</span>
              </Nav.Link> */}
              <Nav.Link className="py-0 my-2 px-lg-4 px-0" as={Link} to="/spa">
                <span>Spa</span>
              </Nav.Link>
              <Nav.Link
                className="py-0 my-2 px-lg-4 px-0"
                as={Link}
                to="/packages"
              >
                <span>Packages</span>
              </Nav.Link>
              <Nav.Link
                className="py-0 my-2 px-lg-4 px-0"
                as={Link}
                to="/contact-us"
              >
                <span>Contact Us</span>
              </Nav.Link>
              <Nav.Link className="py-0 my-2 px-lg-4 px-0" as={Link} to="/ref">
                <span>Reference</span>
              </Nav.Link>
            </Nav>
            {localStorage.getItem("roles") ? (
              <>
                <Dropdown className="cursor-pointer">
                  <Dropdown.Toggle
                    variant=""
                    className="d-flex align-items-center p-0 gap-1 border-0"
                    id="dropdown-basic"
                  >
                    <Image
                      className="w-7 h-7 rounded-circle object"
                      src={data?.profile_photo}
                      alt="User"
                      width="28px"
                    />
                    <span className="text-xs text-dark font-bold">
                      {data?.name}
                    </span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu align="end" className="start-0">
                    <Dropdown.Item to="/profile" as={Link}>
                      <i className="bi bi-person-vcard me-3"></i>Profile
                    </Dropdown.Item>
                    <Dropdown.Item to={"/user/package"} as={Link}>
                      <i className="bi bi-file-earmark-plus me-3"></i>
                      Membership
                    </Dropdown.Item>
                    <Dropdown.Item to={"/reflist"} as={Link}>
                      <i className="bi bi-file-earmark-plus me-3"></i>
                      Reference List
                    </Dropdown.Item>
                    {/* <Dropdown.Item to={"/user/scanner"} as={Link}>
                      <i className="bi bi-file-earmark-plus me-3"></i>Scanner
                    </Dropdown.Item> */}
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={handleLogut}>
                      <i className="bi bi-box-arrow-left me-3"></i>Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            ) : (
              <>
                <Stack gap={3} direction="horizontal">
                  <Link to="/login">
                    <Stack direction="horizontal" gap="2">
                      <Image
                        className="rounded-circle"
                        width={28}
                        src={tempProfile}
                        alt="Temp profile"
                      />
                      <span className="text-xs text-dark font-bold lh-tighter text-primary-hover">
                        Login or
                        <br /> Create Account
                      </span>
                    </Stack>
                  </Link>
                </Stack>
              </>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
export default Header;
