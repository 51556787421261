import React, { useEffect } from "react";
import { Header, Footer } from "../components";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Image,
  Row,
} from "react-bootstrap";
import { PackagesCard } from "../components/Slider";
import {
  spaBanner,
  welcomeToTherapy,
  bannerExperience,
} from "../assets/images";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPackage } from "../redux/packages";

function Home() {
  const herostyle = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url(${spaBanner})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const dispatch = useDispatch();

  const allPackages = useSelector((state) => state.package.allPackages);

  function isObjectEmpty(data) {
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  useEffect(() => {
    if (allPackages && isObjectEmpty(allPackages)) {
      dispatch(getPackage());
    }
  }, [allPackages]);

  return (
    <>
      <Header />
      <section
        className="d-flex align-items-center py-20 overflow-hidden background-no-repeat bg-center bg-cover min-h-screen-banner mb-lg-20 mb-10"
        style={herostyle}
      >
        <Container className="overlap-20 vstack">
          <div className="my-auto mx-auto text-center">
            <span className="text-gray-200">Therapyzone</span>
            <h1 className="mb-4 text-light text-main font-bolder">
              Make Your Soul Smile Again
            </h1>
            <p className="mb-4 text-gray-200 max-w-screen-sm mx-auto">
              It’s time to sit back, relax and allow us to pamper you. At
              SPA.ce, we offer unique spa packages for men, women, couples and
              groups.
            </p>
            <div className="hstack flex-column gap-4 justify-content-center">
              <Button
                variant="light"
                size="lg"
                as={Link}
                to="./spa"
                className="rounded-pill"
              >
                View All Spa
              </Button>
              <Button
                variant="light"
                size="lg"
                as={Link}
                to="./packages"
                className="rounded-pill"
              >
                View Packages
              </Button>
            </div>
          </div>
          {/* <div className='mt-auto'>
          </div> */}
        </Container>
      </section>
      <PackagesCard data={allPackages} MorePackagesButton={true} />
      <section className="mb-lg-20 mb-10">
        <Container className="overlap-20 vstack">
          <Row className="align-items-center gy-6">
            <Col lg={6} className="text-center">
              <Image src={welcomeToTherapy} />
            </Col>
            <Col lg={6}>
              <h2 className="h1 mb-3 font-bolder">Welcome to Therapy zone</h2>
              <p>
                Welcome to Therapy Zone in Ahmedabad, your sanctuary for
                ultimate relaxation and rejuvenation. Immerse yourself in a
                world of tranquility as our expert therapists pamper you with
                bespoke treatments designed to soothe your mind, body, and soul.
                From revitalizing massages to luxurious skincare rituals, we
                offer a personalized escape from the hustle and bustle of
                everyday life. Experience a haven of serenity at Therapy Zone,
                where every visit is a journey to wellness.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <div>
        <h1>QR Code Scanner</h1>
        <h2>Data: {data}</h2>
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          width="100%"
          height="100%"
          videoConstraints={{ facingMode: camera }}
        />
        <button onClick={toggleCamera}>
          Switch to {camera === 'environment' ? 'Front' : 'Back'} Camera
        </button>
        
      </div> */}
      <section className="mb-lg-20 mb-10">
        <Container>
          <Card className="bg-orange-200">
            <CardBody className="py-10">
              <h2 className="mb-10 text-center font-bolder text-wood-gradient">
                How Does it Work?
              </h2>
              <Row className="gy-6">
                <Col lg={3}>
                  <Card className="h-full">
                    <CardBody>
                      <h3 className="h1 mb-4 text-primary font-bolder">01</h3>
                      <h4 className="mb-2">Choose a Membership Plan</h4>
                      <p>
                        Select your desired membership plan and add the required
                        details.
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={3}>
                  <Card className="h-full">
                    <CardBody>
                      <h3 className="h1 mb-4 text-primary font-bolder">02</h3>
                      <h4 className="mb-2">Pay for Your Membership</h4>
                      <p>
                        Select a payment method and complete the transaction.
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={3}>
                  <Card className="h-full">
                    <CardBody>
                      <h3 className="h1 mb-4 text-primary font-bolder">03</h3>
                      <h4 className="mb-2">Select your spa</h4>
                      <p>Find our near spa and best services</p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={3}>
                  <Card className="h-full">
                    <CardBody>
                      <h3 className="h1 mb-4 text-primary font-bolder">04</h3>
                      <h4 className="mb-2">Enjoy your spa treatment</h4>
                      <p>
                        Visit the spa on the appointed date and indulge in a
                        relaxing spa session.
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </section>
      <section
        className="position-relative d-flex align-items-center py-20 background-no-repeat bg-center bg-cover min-h-screen-banner"
        style={{ backgroundImage: `url(${bannerExperience})` }}
      >
        <div className="color-tint"></div>
        <Container className="my-lg-auto my-16 position-relative overlap-20">
          <div className="max-w-screen-sm mb-lg-20 mb-8">
            <span className="text-gray-400 mb-2 d-block">WHAT ELSE WE DO</span>
            <h2 className="h1 mb-5 text-light">
              Get An Incredible Experience with Therapy Zone Thai
            </h2>
            <p className="text-gray-500">
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, buying to
              injected humour, or randomised words which don't look even many
              desktop publishing packages,All message will be of 60 minutes
              duration.
            </p>
          </div>
          <Row className="gy-3 text-gray-500">
            <Col lg={3} md={6}>
              <ul className="list-check-1 list-unstyled vstack gap-3 mb-0">
                <li className="text-light">Head &amp; Shoulder Massage</li>
                <li className="text-light">Foot Massage</li>
                <li className="text-light">Relax Massage</li>
                <li className="text-light">OIL Massage</li>
              </ul>
            </Col>
            <Col lg={3} md={6}>
              <ul className="list-check-1 list-unstyled vstack gap-3 mb-0">
                <li className="text-light">Aroma Massage</li>
                <li className="text-light">Deep Tissue Massage</li>
                <li className="text-light">LOMI LOMI Massage</li>
                <li className="text-light">Thai Massage</li>
              </ul>
            </Col>
            <Col lg={3} md={6}>
              <ul className="list-check-1 list-unstyled vstack gap-3 mb-0">
                <li className="text-light">Swedish Massage</li>
                <li className="text-light">Balinese Massage</li>
                <li className="text-light">Back Massage</li>
                <li className="text-light">Body Polish</li>
              </ul>
            </Col>
            <Col lg={3} md={6}>
              <ul className="list-check-1 list-unstyled vstack gap-3 mb-0">
                <li className="text-light">Cream Massage (Full Body)</li>
                <li className="text-light">Gel Massage (Full Body)</li>
                <li className="text-light">Scrub Massage (Full Body)</li>
                <li className="text-light">hammam Massage </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>

      {/* <TypeOfSpa /> */}
      <Footer />
    </>
  );
}

export default Home;
