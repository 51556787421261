import React, { useEffect } from "react";
import { AdminHeader, AdminSidebar } from "../../components";
import { Button,Col, Container, Image, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { createQRCode } from "../../redux/qrcode";
import { useNavigate } from "react-router";

function AdminCreateQrcode() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault(); // Corrected method name

    dispatch(createQRCode());
  };

  const addQRcode = useSelector((state) => state.qrcode.createQRCodeAdd);
  
  
  const addQRcodeIsLoading = useSelector((state) => state.qrcode.createQRCodeAddIsLoading);

  

  useEffect(() => {
    if (addQRcode?.status === 401) {
      localStorage.clear();
      navigate("spa/login");
    }
  }, [addQRcode, navigate]); // Added navigate as a dependency


  return (
    <div>
      <div className="d-flex flex-column flex-lg-row h-lg-full min-h-screen bg-gray-200 vstack">
        <AdminSidebar />
        <div className="flex-lg-1 h-lg-screen overflow-y-lg-auto">
          <AdminHeader title={"QR Code"} />
          <Container fluid className="py-6 gx-md-12">
            <Row xxl={4} sm={2} className="gy-6">
              <Col>
                <Button onClick={handleSubmit} disabled={addQRcodeIsLoading}>
                  {addQRcodeIsLoading ? "Creating..." : "Create QR Code"}
                </Button>
                {addQRcode.qrCode ? (
                  <Image src={addQRcode.qrCode} alt="QR Code" style={{ width: '200px', height: '200px' }} />
                ) : <h2>Please Click Create QR Code Button</h2>}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default AdminCreateQrcode;
