// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tv-header {
  position: sticky !important;
  top: 0 !important;
  z-index: 1020 !important;
}
.tv-header .navbar-collapse::before {
  content: none;
}
.tv-header .navbar-collapse .navbar-nav .nav-link span {
  position: relative;
  display: block;
  width: max-content;
}
.tv-header .navbar-collapse .navbar-nav .nav-link span::after {
  height: 2px;
  width: 0;
  background: #bf5337;
  content: "";
  position: absolute;
  right: 0;
  bottom: -2px;
  transition: 0.7s all;
}
.tv-header .navbar-collapse .navbar-nav .nav-link:hover {
  color: #192432;
}
.tv-header .navbar-collapse .navbar-nav .nav-link:hover span::after {
  width: 100%;
  right: auto;
  left: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/header.scss"],"names":[],"mappings":"AAAA;EACI,2BAAA;EACA,iBAAA;EACA,wBAAA;AACJ;AAEQ;EACI,aAAA;AAAZ;AAKgB;EACI,kBAAA;EACA,cAAA;EACA,kBAAA;AAHpB;AAKoB;EACI,WAAA;EACA,QAAA;EACA,mBAAA;EACA,WAAA;EACA,kBAAA;EACA,QAAA;EACA,YAAA;EACA,oBAAA;AAHxB;AAOgB;EACI,cAAA;AALpB;AAQwB;EACI,WAAA;EACA,WAAA;EACA,OAAA;AAN5B","sourcesContent":[".tv-header {\r\n    position: sticky !important;\r\n    top: 0 !important;\r\n    z-index: 1020 !important;\r\n\r\n    .navbar-collapse {\r\n        &::before {\r\n            content: none;\r\n        }\r\n\r\n        .navbar-nav {\r\n            .nav-link {\r\n                span {\r\n                    position: relative;\r\n                    display: block;\r\n                    width: max-content;\r\n\r\n                    &::after {\r\n                        height: 2px;\r\n                        width: 0;\r\n                        background: #bf5337;\r\n                        content: \"\";\r\n                        position: absolute;\r\n                        right: 0;\r\n                        bottom: -2px;\r\n                        transition: 0.7s all;\r\n                    }\r\n                }\r\n\r\n                &:hover {\r\n                    color: #192432;\r\n\r\n                    span {\r\n                        &::after {\r\n                            width: 100%;\r\n                            right: auto;\r\n                            left: 0;\r\n                        }\r\n                    }\r\n                }\r\n            }\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
