import { createSlice } from "@reduxjs/toolkit";
import {
  getPackage,
  addPackage,
  activeSpa,
  getPackageType,
  addPackageType,
} from "./index";

const packageSlice = createSlice({
  name: "packageSlice",
  initialState: {
    allPackages: {},
    allPackagesType: {},
    packageAdd: {},
    packageAddType:{},
    allActiveSpa: {},
    allPackagesIsLoading: false,
    activeSpaIsLoading: false,
    addPackageIsLoading: false,
    allPackagesTypeIsLoading: false,
    addPackageIsTypeLoading:false,
    error: null,
  },
  reducers: {
    clearPackageAdd: (state, action) => {
      state.packageAdd = {};
    },
    clearPackageTypeAdd: (state, action) => {
      state.packageAddType = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPackage.pending, (state) => {
        state.allPackagesIsLoading = true;
      })
      .addCase(getPackage.fulfilled, (state, action) => {
        state.allPackages = action.payload;
        state.allPackagesIsLoading = false;
      })
      .addCase(getPackage.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.allPackagesIsLoading = false;
      })
      .addCase(getPackageType.pending, (state) => {
        state.allPackagesTypeIsLoading = true;
      })
      .addCase(getPackageType.fulfilled, (state, action) => {
        state.allPackagesType = action.payload;
        state.allPackagesTypeIsLoading = false;
      })
      .addCase(getPackageType.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.allPackagesTypeIsLoading = false;
      })
      .addCase(addPackageType.pending, (state) => {
        state.addPackageIsTypeLoading = true;
      })
      .addCase(addPackageType.fulfilled, (state, action) => {
        state.packageAddType = action.payload;
        state.addPackageIsTypeLoading = false;
      })
      .addCase(addPackageType.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.addPackageIsTypeLoading = false;
      })
      .addCase(activeSpa.pending, (state) => {
        state.activeSpaIsLoading = true;
      })
      .addCase(activeSpa.fulfilled, (state, action) => {
        state.allActiveSpa = action.payload;
        state.activeSpaIsLoading = false;
      })
      .addCase(activeSpa.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.activeSpaIsLoading = false;
      })
      .addCase(addPackage.pending, (state) => {
        state.addPackageIsLoading = true;
      })
      .addCase(addPackage.fulfilled, (state, action) => {
        state.packageAdd = action.payload;
        state.addPackageIsLoading = false;
      })
      .addCase(addPackage.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.addPackageIsLoading = false;
      });
  },
});

export default packageSlice.reducer;
export const { clearPackageAdd,clearPackageTypeAdd } = packageSlice.actions;