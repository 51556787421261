import React, { useEffect, useState } from "react";
import { Navbar, Nav, Dropdown, Button, Image } from "react-bootstrap";
import { logo } from "../../assets/images";
import { GrAppsRounded } from "react-icons/gr";
import { GoPackage } from "react-icons/go";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { spaownerDetailData } from "../../redux/user";
import { getAllSpaData } from "../../redux/bookspa";

function AdminSidebar() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [role, setRole] = useState(localStorage.getItem("roles") || "");

  const userRole = useSelector((state) => state.user.userRole);
  const getAllSpa = useSelector((state) => state.bookspa.getAllSpa);

  useEffect(() => {
    if (userRole) setRole(userRole);
  }, [userRole]);

  useEffect(() => {
    if (getAllSpa.length === 0) {
      dispatch(getAllSpaData());
    }
  }, [getAllSpa]);

  const getInactiveSpaCount = getAllSpa.filter(spa => spa.Active === false).length;

  const handleLogout = () => {
    if (localStorage.getItem("roles") === "spa_owner") {
      localStorage.clear();
      navigate("/spa/login");
    }
    if (localStorage.getItem("roles") === "admin") {
      localStorage.clear();
      navigate("/admin/login");
    }
  };

  const spaownerDetails = useSelector((state) => state.user.spaownerDetails);

  useEffect(() => {
    if (
      localStorage.getItem("therapyToken") &&
      localStorage.getItem("roles") === "spa_owner"
    ) {
      if (!spaownerDetails.user) {
        dispatch(spaownerDetailData());
      }
    }
  }, [spaownerDetails, dispatch]);
  return (
    <Navbar
      className="navbar-vertical h-lg-screen navbar-expand-lg px-0 py-3 navbar-light bg-white border-bottom border-bottom-lg-0 border-end-lg mb-0"
      expand="lg"
      id="sidebar"
    >
      <div className="container-fluid gx-md-12">
        <Navbar.Toggle aria-controls="sidebarCollapse" />
        <Navbar.Brand
          to="/"
          as={Link}
          className="d-inline-block py-lg-2 py-0 mb-lg-5 px-lg-6 me-0"
        >
          <Image
            width={204}
            src={logo}
            alt="therapy zone"
            className="w-lg-auto w-sm-40 w-32"
          />
        </Navbar.Brand>

        {/* Mobile user dropdown */}
        <div className="navbar-user d-lg-none">
          <Dropdown>
            <Dropdown.Toggle
              id="sidebarAvatar"
              className="d-flex align-items-center gap-2"
              as={Button}
            >
              <div className="d-sm-block d-none">
                <span className="h6 d-block">
                  {spaownerDetails?.user?.name}
                </span>
                <span className="text-xs text-muted d-block">spa owner</span>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu align="end">
              <Dropdown.Item to={"/admin/profile"} as={Link}>
                <i className="bi bi-person-vcard me-3"></i> profile
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleLogout()}>
                <i className="bi bi-box-arrow-left me-3"></i>
                <span>Logout</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <Navbar.Collapse id="sidebarCollapse" className="px-4">
          <Nav className="custome-navbar">
            {role === "spa_owner" && (
              <>
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/admin/package"
                    className={`${
                      location.pathname.includes("/admin/package")
                        ? "active"
                        : ""
                    }`}
                  >
                    <GoPackage />
                    Customer Details
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/admin/qrcode"
                    className={`${
                      location.pathname.includes("/admin/qrcode")
                        ? "active"
                        : ""
                    }`}
                  >
                    <GoPackage />
                    Create QrCode
                  </Nav.Link>
                </Nav.Item>
              </>
            )}
            {role === "admin" && (
              <>
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/admin/dashboard"
                    className={`${
                      location.pathname.includes("/admin/dashboard")
                        ? "active"
                        : ""
                    }`}
                  >
                    <GrAppsRounded />
                    Dashboard
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/admin/addpackage"
                    className={`${
                      location.pathname.includes("/admin/addpackage")
                        ? "active"
                        : ""
                    }`}
                  >
                    <GoPackage />
                    Package
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/admin/spa/active"
                    className={`${
                      location.pathname.includes("/admin/spa/active")
                        ? "active"
                        : ""
                    }`}
                  >
                    <GrAppsRounded />
                    Spa Active
                    <span className="badge bg-primary text-light ms-2 py-1 px-2">
                    {getInactiveSpaCount}
                    </span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/admin/bookspa"
                    className={`${
                      location.pathname.includes("/admin/bookspa")
                        ? "active"
                        : ""
                    }`}
                  >
                    <GrAppsRounded />
                    Book spa
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/admin/packagetype"
                    className={`${
                      location.pathname.includes("/admin/packagetype")
                        ? "active"
                        : ""
                    }`}
                  >
                    <GrAppsRounded />
                    Package Type
                  </Nav.Link>
                </Nav.Item>
              </>
            )}
            {/* {role === "user" && (
              <>
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/user/package"
                    className={`${
                      location.pathname.includes("/user/package")
                        ? "active"
                        : ""
                    }`}
                  >
                    <GoPackage />
                     Package
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/user/scanner"
                    className={`${
                      location.pathname.includes("/user/scanner")
                        ? "active"
                        : ""
                    }`}
                  >
                    <GoPackage />
                    Scanner
                  </Nav.Link>
                </Nav.Item>
              </>
            )} */}

            {/* <Nav.Item>
              <Nav.Link to="/package"><GrList />Type of Spa</Nav.Link>
            </Nav.Item> */}
            {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
            {/* <Nav.Item>
              <Button
                variant="link"
                className="nav-link w-full"
                onClick={() => setOpenUserMenu(!openUserMenu)}
                aria-expanded={openUserMenu}
              >
                <i className="bi bi-people"></i> User
              </Button>
              <Collapse in={openUserMenu}>
                <div>
                  <Nav className="nav-sm flex-column">
                    <Nav.Item>
                      <Nav.Link href="https://beta.techvoot.com/admin/user/leaves">Leaves</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </Collapse>
            </Nav.Item> */}
            {/* <Nav.Item>
            <Nav.Link
        onClick={() => setOpen(!open)}
        aria-controls="Admin"
        aria-expanded={open}
        role="button"
        className="d-flex align-items-center active"
      >
        Admin
      </Nav.Link>

      <Collapse in={open}>
        <div id="Admin">
          <Nav className="flex-column">
            <Nav.Item>
              <Nav.Link href="https://beta.techvoot.com/admin/employees">Employees</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://beta.techvoot.com/admin/roles">Roles</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://beta.techvoot.com/admin/assets">User Assets</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://beta.techvoot.com/admin/salary-slip">Salary Slip</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://beta.techvoot.com/admin/old-salary-slip">Old Salary Slip</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://beta.techvoot.com/admin/employee-track-email">Email Tracker</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://beta.techvoot.com/admin/employee-email-track-unsubscribe">Email Track Unsubscribe</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://beta.techvoot.com/admin/employee-track-check-emails">Email Track Remove</Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
      </Collapse>
            </Nav.Item> */}
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}

export default AdminSidebar;
