import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../apis/Api";

export const bookspaAdd = createAsyncThunk(
  "bookspaAdd/post",
  async (data, { rejectWithValue, dispatch }) => {

    try {
      const backend = new API();
      const response = await backend.fetch(API.AddBookSpa(), {
        method: "POST",
        body: JSON.stringify(data), // Send the data as JSON
      });
      // ToastMessage(response);
      // You may want to return some data here if needed
      return response;
    } catch (error) {
      console.error("Error send otp:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);

export const refBookspaAdd = createAsyncThunk(
  "refBookspaAdd/post",
  async (data, { rejectWithValue, dispatch }) => {


    try {
      const backend = new API();
      const response = await backend.fetch(API.RefAddBookSpa(), {
        method: "POST",
        body: JSON.stringify(data), // Send the data as JSON
      });
      // ToastMessage(response);
      // You may want to return some data here if needed
      return response;
    } catch (error) {
      console.error("Error send otp:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);

export const getUserBookSpa = createAsyncThunk(
  "getUserBookSpa/get",
  async (data, { rejectWithValue, dispatch }) => {

    try {
      const backend = new API();
      const response = await backend.fetch(API.GetUserBookSpa(), {
        method: "GET",
      });

      // ToastMessage(response);
      // You may want to return some data here if needed
      return response;
    } catch (error) {
      console.error("Error send otp:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);

export const getAllSpaData = createAsyncThunk(
  "getAllSpaData/get",
  async (data, { rejectWithValue, dispatch }) => {

    try {
      const backend = new API();
      const response = await backend.fetch(API.GetAllSpa(), {
        method: "GET",
      });

      // ToastMessage(response);
      // You may want to return some data here if needed
      return response;
    } catch (error) {
      console.error("Error send otp:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);

export const getAllBookSpaData = createAsyncThunk(
  "getAllBookSpaData/get",
  async (data, { rejectWithValue, dispatch }) => {

    try {
      const backend = new API();
      const response = await backend.fetch(API.GetAllBookSpa(), {
        method: "GET",
      });

      // ToastMessage(response);
      // You may want to return some data here if needed
      return response;
    } catch (error) {
      console.error("Error send otp:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);

export const getAdminBookSpa = createAsyncThunk(
  "getAdminBookSpa/get",
  async (data, { rejectWithValue, dispatch }) => {

    try {
      const backend = new API();
      const response = await backend.fetch(API.GetAdminBookSpa(), {
        method: "GET",
      });

      // ToastMessage(response);
      // You may want to return some data here if needed
      return response;
    } catch (error) {
      console.error("Error send otp:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);