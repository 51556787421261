import React, { useEffect, useState } from "react";
import { Footer, Header } from "../components";
import {
  Card,
  CardBody,
  Col,
  Container,
  FloatingLabel,
  Form,
  Image,
  Row,
} from "react-bootstrap";
import { welcomeToTherapy } from "../assets/images";
import { MdOutlineLocationOn } from "react-icons/md";
import { IoCallOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { activeSpa } from "../redux/packages";

function SpaList() {
  const dispatch = useDispatch();
  const [serachAddress, setSerchAddress] = useState("");
  const [serachName, setSerchName] = useState("");
  const allActiveSpa = useSelector((state) => state.package.allActiveSpa);

  function isObjectEmpty(data) {
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  useEffect(() => {
    if (allActiveSpa && isObjectEmpty(allActiveSpa)) {
      
      dispatch(activeSpa(""));
    }
    // Check if `allActiveSpa` is not populated yet and then dispatch.
    // if (!allActiveSpa || allActiveSpa.length === 0) {
    //   dispatch(activeSpa(""));
    // }
  }, [allActiveSpa]);
  // name, address


  const handleSearch = (name, value) => {
    if(name==="name")setSerchName(value)
      if(name==="address")setSerchAddress(value)
    dispatch(
      activeSpa({
        name: name === "name" ? value : serachName,
        address: name === "address" ? value : serachAddress,
      })
    );
  };
  return (
    <>
      <Header />
      <Container className="mt-lg-10 mt-10 mb-6">
        <Row className="gy-4">
          <Col lg={4}>
            <FloatingLabel controlId="floatingInput" label="Search Area">
              <Form.Control
                type="text"
                placeholder="Search"
                onChange={(e) => handleSearch("address", e.target.value)}
              />
            </FloatingLabel>
          </Col>
          <Col lg={4}>
            <FloatingLabel controlId="floatingPassword" label="Search Spa">
              <Form.Control
                type="text"
                placeholder="Spa"
                onChange={(e) => handleSearch("name", e.target.value)}
              />
            </FloatingLabel>
          </Col>
        </Row>
      </Container>
      <Container className="mb-lg-20 mb-10">
        <Row className="gy-6 mt-6">
          {allActiveSpa && 
            allActiveSpa?.data?.map((item, index) => {
              return (
                <Col lg={6} key={index}>
                  <Card className="d-md-flex flex-md-row overflow-hidden h-full border border-gray-400 overflow-hidden">
                    <Image
                      className="w-full h-48 w-md-48 h-md-auto mx-auto object"
                      width="384"
                      height="512"
                      src={item.image ? item.image : welcomeToTherapy}
                    />
                    <CardBody className="p-md-4">
                      <h4 className="mb-3">{item.name}</h4>
                      <p className="mb-1">
                        <MdOutlineLocationOn className="me-1 text-primary font-bold" />
                        {item.address}
                      </p>
                      <p>
                        <IoCallOutline className="me-1 text-primary" />
                        <a
                          className="text-gray-700 text-base "
                          href="tel:+917688005555"
                        >
                          {item.phone_no}
                        </a>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default SpaList;
