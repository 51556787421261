 import React from "react";
import { AdminHeader, AdminSidebar } from "../../components";
import { Card, CardBody, Col, Container, Row } from "react-bootstrap";
import { GoPackage } from "react-icons/go";
import { GrList } from "react-icons/gr";

function AdminDeshboard() {
  return (
    <div className="d-flex flex-column flex-lg-row h-lg-full min-h-screen bg-gray-200 vstack">
      <AdminSidebar />
      <div className="flex-lg-1 h-lg-screen overflow-y-lg-auto">
      <AdminHeader title={"Dashboard"} />
      <Container fluid className="py-6 gx-md-12">
        <Row xxl={4} sm={2} className="gy-6">
          <Col>
          <Card className="h-full">
            <CardBody>
            <Row>
              <Col>
                  <h6 class="font-bold text-muted text-sm mb-2">Total packages</h6>
                  <span class="h3 font-bold">0</span>
              </Col>
              <Col className="col-auto">
                  <div class="bg-tertiary text-white text-xl rounded-circle h-12 w-12 hstack justify-content-center">
                  <GoPackage />
                  </div>
              </Col>
          </Row>
            </CardBody>
          </Card>
          </Col>
          <Col>
          <Card className="h-full">
            <CardBody>
            <Row>
              <Col>
                  <h6 class="font-bold text-muted text-sm mb-2">Type Of Spa</h6>
                  <span class="h3 font-bold">0</span>
              </Col>
              <Col className="col-auto">
                  <div class="bg-warning text-white text-xl rounded-circle h-12 w-12 hstack justify-content-center">
                  <GrList />
                  </div>
              </Col>
          </Row>
            </CardBody>
          </Card>
          </Col>

        </Row>
      </Container>
      </div>
    </div>
  );
}

export default AdminDeshboard;
